import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/analytics';
import 'firebase/remote-config';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const privateChatDB = process.env.REACT_APP_PRIVATE_CHAT_DB || 'messages';
const classroomChatDB = process.env.REACT_APP_CLASSROOM_CHAT_DB || 'classroom-messages';

firebase.initializeApp(config);
const firebaseAnalytics = firebase.analytics();

const remoteConfig = firebase.remoteConfig();

remoteConfig.defaultConfig = {
  config: 'default_value',
};

remoteConfig.settings = {
  minimumFetchIntervalMillis: 3600000,
  fetchTimeoutMillis: 60000,
};

const firebaseAuth = firebase.auth();
firebaseAuth.signInAnonymously();

let messaging = null;
let getTokenFirebase = null;
let isNotifSupported = false;

if (firebase.messaging.isSupported()) {
  try {
    messaging = firebase.messaging();
    getTokenFirebase = async () => {
      return await messaging.getToken({ vapidKey: process.env.REACT_APP_VAPID_KEY });
    };
    isNotifSupported = true;
  } catch (e) {
    console.log(e);
  }
}

const onMessageListener = () => {
  // i am commmenting this because it keep sending twice
  // return new Promise((resolve) => {
  //   messaging.onMessage((payload) => {
  //     resolve(payload);
  //   });
  // });
};
const fetchRemoteConfig = async () => {
  try {
    await remoteConfig.fetchAndActivate();
    console.log('Remote config activated successfully');
  } catch (error) {
    console.error('Error fetching and activating remote config:', error);
  }
};

const getConfigValue = key => {
  return remoteConfig.getValue(key).asString();
};

export {
  firebase,
  privateChatDB,
  classroomChatDB,
  firebaseAuth,
  firebaseAnalytics,
  messaging,
  getTokenFirebase,
  onMessageListener,
  isNotifSupported,
  fetchRemoteConfig,
  getConfigValue,
};
