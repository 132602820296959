import React from 'react';
import { Button } from 'rsuite';
import PropTypes from 'prop-types';
import { css } from 'styled-components/macro';

const PrimaryLink = css`
  border-radius: 5px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  text-align: center;

  color: #0ecab8;
  padding: 0;
  margin: 0;
  box-shadow: none;
  display: inline-block;
  height: auto;
  background: transparent;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    color: #0ecab8;
    background: transparent;
  }

  &:disabled,
  &.rs-btn-disabled {
    opacity: 0.75;
    box-shadow: none;
    background: transparent;
  }
`;

/**
 * Custom button component using rsuite default button, ref: https://v4.rsuitejs.com/components/button
 */
const ButtonLinkPrimary = ({
  appearance,
  isActive,
  isBlock,
  size,
  isLoading,
  href,
  isDisabled,
  label,
  onClick,
  isRipple,
  children,
  ...rest
}) => {
  return (
    <Button
      block={isBlock}
      size={size}
      loading={isLoading}
      href={href}
      disabled={isDisabled}
      onClick={onClick}
      ripple={isRipple}
      css={PrimaryLink}
      {...rest}
    >
      {label}
      {children}
    </Button>
  );
};

ButtonLinkPrimary.propTypes = {
  /**
   * A button can have a label text
   */
  label: PropTypes.string,
  /**
   * A button can have different sizes
   */
  size: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']),
  /**
   * Providing a href will render an a element
   */
  href: PropTypes.string,
  /**
   * Spans the full width of the Button parent
   */
  isBlock: PropTypes.bool,
  /**
   * A button can show a loading indicator
   */
  isLoading: PropTypes.bool,
  /**
   * A button can show it is currently unable to be interacted with
   */
  isDisabled: PropTypes.bool,
  /**
   * A button can have ripple effect
   */
  isRipple: PropTypes.bool,
  /**
   * A button can handler click event
   */
  onClick: PropTypes.func,
};

ButtonLinkPrimary.defaultProps = {
  label: 'simpan',
  isBlock: false,
  size: 'lg',
  isLoading: false,
  isDisabled: false,
  isRipple: true,
};

export default ButtonLinkPrimary;
