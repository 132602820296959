import { getAccessRole, getAccessToken } from '../configs/cookies';
import jwt_decode from 'jwt-decode';
import { isEmpty, isNil } from 'lodash';
import { switchRole } from 'helpers/switchRole';

export const TOKEN_ERROR = {
  EMPTY_ACCESS_TOKEN: 'EMPTY_ACCESS_TOKEN',
  INVALID_ACCESS_TOKEN: 'INVALID_ACCESS_TOKEN',
  EXPIRED_ACCESS_TOKEN: 'EXPIRED_ACCESS_TOKEN',
  INVALID_SAVED_ROLE: 'INVALID_SAVED_ROLE',
};

/**
 * Used to check user role id saved in cookies is valid or not
 * @returns Promise
 */
export const getAccessRoleIdStatus = () => {
  const savedRoleId = getAccessRole();
  const role = switchRole(savedRoleId);
  if (isNil(role)) return TOKEN_ERROR.INVALID_SAVED_ROLE;
  return 'VALID';
};

/**
 * Used to check access token status
 * @returns Promise
 */
export const getAccessTokenStatus = () => {
  const accessToken = getAccessToken();
  if (isEmpty(accessToken)) return TOKEN_ERROR.EMPTY_ACCESS_TOKEN;

  const { exp } = jwt_decode(accessToken);
  if (isNil(exp)) return TOKEN_ERROR.INVALID_ACCESS_TOKEN;

  // comment cause bug on first mount
  const expiredAt = exp * 1000;
  const now = new Date().getTime();
  if (expiredAt <= now) return TOKEN_ERROR.EXPIRED_ACCESS_TOKEN;

  return 'VALID';
};
