import PREFERENCES_ACTION_TYPES from './preferences.types';

const INITIAL_STATE = {
  tour: {
    home: {
      isCompleted: false,
      completedType: null, // finish or skip
    },
    history: {
      isCompleted: false,
      completedType: null, // finish or skip
    },
    tugasKuHome: {
      isCompleted: false,
      completedType: null, // finish or skip
    },
    tugasKuClass: {
      isCompleted: false,
      completedType: null, // finish or skip
    },
  },
  socmedSkip: false,
};

const preferenceReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PREFERENCES_ACTION_TYPES.SET_IS_COMPLETED_TOUR_IN_HOME:
      return {
        ...state,
        tour: {
          ...state.tour,
          home: payload,
        },
      };
    case PREFERENCES_ACTION_TYPES.SET_IS_COMPLETED_TOUR_IN_HISTORY:
      return {
        ...state,
        tour: {
          ...state.tour,
          history: payload,
        },
      };
    case PREFERENCES_ACTION_TYPES.SET_IS_COMPLETED_TOUR_IN_TUGASKU_HOME:
      return {
        ...state,
        tour: {
          ...state.tour,
          tugasKuHome: payload,
        },
      };
    case PREFERENCES_ACTION_TYPES.SET_IS_COMPLETED_TOUR_IN_TUGASKU_CLASS:
      return {
        ...state,
        tour: {
          ...state.tour,
          tugasKuClass: payload,
        },
      };
    case PREFERENCES_ACTION_TYPES.SET_PREFERENCES_FROM_LOCAL_STORAGE:
      return {
        ...state,
        ...payload,
      };
    case PREFERENCES_ACTION_TYPES.RESET_PREFERENCES:
      return { ...INITIAL_STATE };
    case PREFERENCES_ACTION_TYPES.SET_SKIP_SOCMED:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default preferenceReducer;
