import { lazy } from 'react';
const Success = lazy(() => import('views/Success'));
const BlockList = lazy(() => import('views/messanger/BlockList'));
const GroupDirectChat = lazy(() => import('views/messangerGroup/GroupDirectChat'));
const VirtualClassDetail = lazy(() => import('views/virtualClass/VirtualClassDetail'));
const VirtualClassEdit = lazy(() => import('views/virtualClass/VirtualClassEdit'));
const VirtualClass = lazy(() => import('views/virtualClass/VirtualClass'));
const Setting = lazy(() => import('views/setting/Setting'));
const Faq = lazy(() => import('views/setting/pages/Faq'));
const Help = lazy(() => import('views/setting/pages/Bantuan'));
const Notification = lazy(() => import('views/setting/pages/Notification'));
const DeleteAccount = lazy(() => import('views/setting/pages/DeleteAccount'));
const Account = lazy(() => import('views/setting/pages/Account'));
const ChangePassword = lazy(() => import('views/setting/pages/ChangePassword'));
const Article = lazy(() => import('views/article/Article'));
const ArticleDetail = lazy(() => import('views/article/ArticleDetail'));
const ArticleSearch = lazy(() => import('views/article/ArticleSearch'));
const Madding = lazy(() => import('views/madding/madding.page'));

const LastChat = lazy(() => import('views/messanger-v2/last-chat.page'));
const DirectChat = lazy(() => import('views/messanger/DirectChat'));
const ContactList = lazy(() => import('views/messanger/ContactList'));
const ForwardMessage = lazy(() => import('views/messanger/ForwardMessage'));
const SearchContact = lazy(() => import('views/messanger/SearchContact'));
const SearchContactResult = lazy(() => import('views/messanger/SearchContactResult'));

const Book = lazy(() => import('views/_student/book/Book'));
const FavoriteBook = lazy(() => import('views/_student/book/FavoriteBook'));
const BookLists = lazy(() => import('views/_student/book/BookLists'));
const BookReader = lazy(() => import('views/_student/book/BookReader'));
const DownloadBook = lazy(() => import('views/_student/book/DownloadBook'));

const DuniaGamesDetails = lazy(() => import('views/duniaGames/DuniaGamesDetails'));
const DuniaGames = lazy(() => import('views/duniaGames/DuniaGames'));
const UserGames = lazy(() => import('views/duniaGames/UserGames'));
const UserGamesPost = lazy(() => import('views/duniaGames/UserGamesPost'));
const GamesLandingPage = lazy(() => import('views/duniaGames/UserGamesLandingPage'));

const Attendance = lazy(() => import('views/attendance/attendance.page'));
const AttendanceSuccess = lazy(() => import('views/attendance/attendance-success.page'));
const AttendanceOnLeave = lazy(() => import('views/attendance/attendance-on-leave.page'));
const AttendanceSubmit = lazy(() => import('views/attendance/attendance-submit.page'));
const AnnouncementParticipantsDetail = lazy(() =>
  import('views/homeroom/AnnouncementParticipantsDetail'),
);

const ConnectSocialMedia = lazy(() =>
  import('views/connect-social-media/connect-social-media.page'),
);
const CreateActivity = lazy(() => import('views/activities/create-activity.page'));
const UpdateActivity = lazy(() => import('views/activities/update-activity.page'));

const RestrictTsel = lazy(() => import('views/restrict-tsel/restrict-tsel.page'));

const ComingSoonPage = lazy(() => import('views/ComingSoon'));
const Beasiswaku = lazy(() => import('views/beasiswaku/Beasiswaku'));

const commonRoutes = [
  {
    path: `/restrict-tsel`,
    exact: true,
    component: RestrictTsel,
    pageTitle: 'Hubungkan Sekolah',
    closeEnv: [],
    allowedRole: ['student', 'teacher'],
  },
  {
    path: `/message`,
    exact: true,
    component: LastChat,
    pageTitle: 'Pesan Terakhir',
    closeEnv: [],
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/message/search/contact`,
    exact: true,
    component: SearchContact,
    pageTitle: 'Cari Kontak',
    closeEnv: [],
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/message/contact`,
    exact: true,
    component: SearchContactResult,
    pageTitle: 'Kontak Baru',
    closeEnv: [],
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/message/new`,
    exact: true,
    component: ContactList,
    pageTitle: 'Pesan Baru',
    closeEnv: [],
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/message/direct/:slug`,
    exact: true,
    component: DirectChat,
    pageTitle: 'Pesan',
    closeEnv: [],
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/message/forward`,
    exact: true,
    component: ForwardMessage,
    pageTitle: 'Teruskan Pesan',
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/message/block-list`,
    exact: true,
    component: BlockList,
    pageTitle: 'Kontak Diblokir',
    closeEnv: ['production'],
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/message-group/direct/:slug`,
    exact: true,
    component: GroupDirectChat,
    pageTitle: 'Pesan Grup',
    closeEnv: [],
    allowedRole: ['teacher', 'student', 'parent'],
  },

  {
    path: `/mading`,
    exact: true,
    component: Madding,
    pageTitle: 'Madding',
    allowedRole: ['student', 'parent', 'teacher'],
  },

  {
    path: `/article`,
    exact: true,
    component: Article,
    pageTitle: 'Artikel',
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/article/search`,
    exact: true,
    component: ArticleSearch,
    pageTitle: 'Cari Artikel',
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/article/:slug`,
    exact: true,
    component: ArticleDetail,
    pageTitle: 'Lihat Artikel',
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/setting`,
    exact: true,
    component: Setting,
    pageTitle: 'Pengaturan',
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/setting/faq`,
    exact: true,
    component: Faq,
    pageTitle: 'FAQ',
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/setting/help`,
    exact: true,
    component: Help,
    pageTitle: 'Bantuan',
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/setting/account`,
    exact: true,
    component: Account,
    pageTitle: 'Akun Saya',
    allowedRole: ['student', 'parent', 'teacher'],
  },

  {
    path: `/setting/account/delete`,
    exact: true,
    component: DeleteAccount,
    pageTitle: 'Hapus Akun',
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/setting/notification`,
    exact: true,
    component: Notification,
    pageTitle: 'Notifikasi',
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/setting/change-password`,
    exact: true,
    component: ChangePassword,
    pageTitle: 'Ganti Password',
    allowedRole: ['student', 'parent', 'teacher'],
  },

  {
    path: `/ebook`,
    exact: true,
    component: Book,
    pageTitle: 'E-book',
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/ebook/lists`,
    exact: true,
    component: BookLists,
    pageTitle: 'Daftar E-book',
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/ebook/favorite`,
    exact: true,
    component: FavoriteBook,
    pageTitle: 'E-book Favorit',
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/ebook/downloaded`,
    exact: true,
    component: DownloadBook,
    pageTitle: 'E-book Unduhan',
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/ebook/read`,
    exact: true,
    component: BookReader,
    pageTitle: 'Baca E-book',
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/voucher-games`,
    exact: true,
    component: DuniaGames,
    pageTitle: 'Katalog Voucher Games',
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/voucher-games/:slug`,
    exact: true,
    component: DuniaGamesDetails,
    pageTitle: 'Voucher Games',
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/user/games`,
    exact: true,
    component: UserGames,
    pageTitle: 'User Games',
    allowedRole: ['student'],
  },
  {
    path: `/user/games/:type`,
    exact: true,
    component: UserGames,
    pageTitle: 'User Games',
    allowedRole: ['student'],
  },
  {
    path: `/user/games-post`,
    exact: true,
    component: UserGamesPost,
    pageTitle: 'User Games',
    allowedRole: ['student'],
  },
  {
    path: `/user/games-post/:type`,
    exact: true,
    component: UserGamesPost,
    pageTitle: 'User Games',
    allowedRole: ['student'],
  },
  {
    path: `/favorite-games`,
    exact: true,
    component: GamesLandingPage,
    pageTitle: 'User Games',
    allowedRole: ['student'],
  },
  {
    path: `/attendance/`,
    exact: true,
    component: Attendance,
    pageTitle: 'Presensi',
    allowedRole: ['student', 'teacher'],
  },
  {
    path: `/attendance/success`,
    exact: true,
    component: AttendanceSuccess,
    pageTitle: 'Presensi',
    allowedRole: ['student', 'teacher'],
  },
  {
    path: `/attendance/on-leave`,
    exact: true,
    component: AttendanceOnLeave,
    pageTitle: 'Presensi',
    allowedRole: ['student', 'teacher'],
  },
  {
    path: `/attendance/submit`,
    exact: true,
    component: AttendanceSubmit,
    pageTitle: 'Presensi',
    allowedRole: ['student', 'teacher'],
  },
  {
    path: `/virtual-class`,
    exact: true,
    component: VirtualClass,
    closeEnv: [],
    pageTitle: 'Kelas Virtual',
    allowedRole: ['teacher', 'student', 'parent'],
  },
  {
    path: `/virtual-class/:uuid`,
    exact: true,
    component: VirtualClassDetail,
    closeEnv: [],
    pageTitle: 'Kelas Virtual',
    allowedRole: ['teacher', 'student', 'parent'],
  },
  {
    path: `/virtual-class/:uuid/edit`,
    exact: true,
    component: VirtualClassEdit,
    closeEnv: [],
    pageTitle: 'Kelas Virtual',
    allowedRole: ['teacher', 'student', 'parent'],
  },
  {
    path: `/success/`,
    exact: true,
    component: Success,
    pageTitle: 'Sukses',
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/announcement/:uuid`,
    exact: true,
    component: AnnouncementParticipantsDetail,
    pageTitle: 'Pengumuman',
    allowedRole: ['student', 'parent'],
  },
  {
    path: '/connect-social-media',
    exact: true,
    component: ConnectSocialMedia,
    pageTitle: 'Hubungkan Sosial Media',
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: '/activities/create',
    exact: true,
    component: CreateActivity,
    pageTitle: 'Buat Jadwal',
    allowedRole: ['student', 'teacher'],
  },
  {
    path: '/activities/update/:activityId',
    exact: true,
    component: UpdateActivity,
    pageTitle: 'Ubah Jadwal',
    allowedRole: ['student', 'teacher'],
  },
  {
    path: `/beasiswakuxschoters`,
    exact: true,
    component: Beasiswaku,
    pageTitle: 'Beasiswaku',
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/games-corner`,
    exact: true,
    component: ComingSoonPage,
    pageTitle: 'Games Corner',
    allowedRole: ['student'],
  },
];
export default commonRoutes;
