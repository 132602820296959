import { createAction } from 'utils/reducer/reducer.utils';
import CHAT_ACTION_TYPES from './chat.types';

export const getChatsStart = ({ isSearching }) =>
  createAction(CHAT_ACTION_TYPES.GET_CHATS_START, { isSearching });

/**
 * @param {object} p
 * @param {object[]} p.chats
 * @param {boolean} p.isHasMore
 */
export const getChatsSuccess = ({ chats, isHasMore }) =>
  createAction(CHAT_ACTION_TYPES.GET_CHATS_SUCCESS, { chats, isHasMore });
export const getChatsFailed = error => createAction(CHAT_ACTION_TYPES.GET_CHATS_FAILED, error);
export const resetChats = () => createAction(CHAT_ACTION_TYPES.RESET_CHATS);
