import { createAction } from 'utils/reducer/reducer.utils';
import PREFERENCES_ACTION_TYPES from './preferences.types';

export const setIsCompletedTourInHome = type =>
  createAction(PREFERENCES_ACTION_TYPES.SET_IS_COMPLETED_TOUR_IN_HOME, {
    isCompleted: true,
    completedType: type ? type : 'finish',
  });

export const setIsCompletedTourInHistory = type =>
  createAction(PREFERENCES_ACTION_TYPES.SET_IS_COMPLETED_TOUR_IN_HISTORY, {
    isCompleted: true,
    completedType: type ? type : 'finish',
  });

export const setIsCompletedTourInTugasKuHome = type =>
  createAction(PREFERENCES_ACTION_TYPES.SET_IS_COMPLETED_TOUR_IN_TUGASKU_HOME, {
    isCompleted: true,
    completedType: type ? type : 'finish',
  });

export const setIsCompletedTourInTugasKuClass = type =>
  createAction(PREFERENCES_ACTION_TYPES.SET_IS_COMPLETED_TOUR_IN_TUGASKU_CLASS, {
    isCompleted: true,
    completedType: type ? type : 'finish',
  });

export const setPreferencesFromLocalStorage = preferences =>
  createAction(PREFERENCES_ACTION_TYPES.SET_PREFERENCES_FROM_LOCAL_STORAGE, preferences);

export const resetPreferences = () =>
  createAction(PREFERENCES_ACTION_TYPES.RESET_PREFERENCES, null);

export const setSkipSocmed = payload =>
  createAction(PREFERENCES_ACTION_TYPES.SET_SKIP_SOCMED, payload);
