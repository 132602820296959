import axios from 'axios';
import { refreshAuthToken } from 'services/auth.services';
import { createAxiosRefreshInterceptor } from 'utils/axios/refresh-interceptor.utils';
import { clearCookies, getAccessToken } from './cookies';
import store from '../store/store';

const API_URL = process.env.REACT_APP_API_URL; // change this using redux host
const API_CHAT_URL = process.env.REACT_APP_API_CHAT_URL; // change this using redux host

export const axiosPublic = axios.create({
  baseURL: API_URL,
  responseType: 'json',
});

export const axiosPrivate = axios.create({
  baseURL: API_URL,
  responseType: 'json',
});

axiosPublic.interceptors.request.use(
  config => {
    const { host } = store.getState().host;
    const { base_url } = host;
    config.baseURL = base_url || API_URL; // needfallback
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

axiosPrivate.interceptors.request.use(
  config => {
    const accessToken = getAccessToken();
    config.headers['Authorization'] = `Bearer ${accessToken}`;
    const { host } = store.getState().host;
    const { base_url, chat_url } = host;
    config.baseURL = base_url || API_URL; // needfallback
    const isChat = config.isChat || false;
    if (isChat) {
      config.baseURL = chat_url || API_CHAT_URL;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

createAxiosRefreshInterceptor(axiosPrivate, refreshAuthToken, {
  retryInstance: axiosPublic,
  onRefreshTokenFailed: error => {
    if (error.response) {
      clearCookies();
      window.location.reload();
    }
  },
});
