import { clearCookies } from 'configs/cookies';
import { firebaseAnalytics } from 'configs/firebase';
import { USER_ROLE } from 'helpers/switchRole';
import { authService, userService } from 'services';
import { resetNotifications } from 'store/notification/notification.action';
import { resetPreferences } from 'store/preferences/preferences.action';
import { isPhoneByU } from 'helpers/helperPhone';
import {
  signInStart,
  setCurrentUser,
  signInFailed,
  signInSuccess,
  signOutFailed,
  signOutStart,
  signOutSuccess,
} from './user.action';
import { resetChats } from 'store/chat/chat.action';

/**
 * Used to set user data
 * @returns void
 */
export const setCurrentUserAsync = () => async dispatch => {
  try {
    let phoneEncrypted = '';
    const { user, userRole } = await userService.getProfile();
    if (isPhoneByU(user.phone)) {
      const { encrypted } = await userService.getPhoneEncrypted();
      phoneEncrypted = encrypted;
    }
    dispatch(setCurrentUser({ user: { ...user, encrypted: phoneEncrypted }, userRole }));
  } catch (error) {}
};

/**
 * Used to check user session and set user data when first time app load
 * @returns void
 */
export const checkUserSessionAsync = () => async dispatch => {
  try {
    const { user, userRole } = await userService.checkUserSession();
    dispatch(signInSuccess({ user, userRole }));
  } catch (_error) {
    // in check session, we don't show any error message to user in case session was expired, just clear cookies & redirect to login again
    clearCookies();
    dispatch(signInFailed({ signInType: null, message: null }));
  }
};

/**
 * Sign in
 * @param {object} param
 * @param {string} param.token
 * @param {'google' | 'facebook'} param.provider
 * @param {string} param.role
 * @param {'general' | 'google' | 'facebook'} param.signInType
 * @returns {void}
 */
export const signInWithGoogleAsync =
  ({ token, provider, role, signInType }) =>
  async dispatch => {
    dispatch(signInStart(signInType));
    try {
      const { user, userRole } = await authService.loginWithSocialMedia({
        token,
        user_type: role,
        provider: provider,
      });

      firebaseAnalytics.setUserProperties({ stakeholder: role });
      firebaseAnalytics.logEvent('login', { method: signInType });

      dispatch(signInSuccess({ user, userRole }));
    } catch (error) {
      dispatch(signInFailed({ signInType, message: error.message }));
    }
  };

/**
 * Sign in
 * @param {object} param
 * @param {string} param.username
 * @param {string} param.password
 * @param {string} param.role
 * @param {'general' | 'google' | 'facebook'} param.signInType
 * @returns {void}
 */
export const signInAsync =
  ({ username, password, role, signInType }) =>
  async dispatch => {
    dispatch(signInStart(signInType));
    try {
      const { key: client_secret, id: client_id } = USER_ROLE[role];

      const { user, userRole } = await authService.login({
        client_id,
        client_secret,
        username,
        password,
      });

      firebaseAnalytics.setUserProperties({ stakeholder: role });
      firebaseAnalytics.logEvent('login', { method: signInType });

      dispatch(signInSuccess({ user, userRole }));
    } catch (error) {
      dispatch(signInFailed({ signInType, message: error.message }));
    }
  };

export const signOutAsync = () => async dispatch => {
  dispatch(signOutStart());
  try {
    await authService.logout();
    dispatch(resetNotifications());
    dispatch(resetPreferences());
    dispatch(resetChats());
    dispatch(signOutSuccess());
  } catch (error) {
    dispatch(signOutFailed(error.message));
  }
};
