/**
 *
 * @param {AxiosInstance} axiosInstance - Axios instance that will be used to attach interceptor
 * @param {Function} refreshTokenLogic - Async Function that will be used to refresh token & return new access token
 * @param {object} options
 * @param {AxiosInstance} options.retryInstance - Axios instance that will be used to retry 401 response in queue. It should be another axios instance, do not use the same instance as axiosInstance, it will cause infinity loop
 * @param {number[]=} options.retryStatus - Http status code that will trigger refresh token logic.
 * @param {Function=} options.onRefreshTokenFailed - Use to handle error in refresh token
 * @return {number} interceptor id
 */
export const createAxiosRefreshInterceptor = (axiosInstance, refreshTokenLogic, options) => {
  const { retryInstance, retryStatus = [], onRefreshTokenFailed } = options;

  const _retryStatus = [401, ...retryStatus];

  let _isRefreshing = false;
  let _failedQueue = [];

  const _subscribeTokenRefresh = cb => {
    _failedQueue.push(cb);
  };

  const _onRefreshed = token => {
    _failedQueue.forEach(cb => cb(token));
  };

  return axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      const { config, response } = error;
      const originalRequest = config;

      if (response && _retryStatus.includes(response.status)) {
        const retryOrigReq = new Promise(resolve => {
          _subscribeTokenRefresh(token => {
            // replace the expired token and retry
            // bugs on axios, so we need to spread the header first
            originalRequest.headers = { ...originalRequest.headers };
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            resolve(retryInstance(originalRequest));
          });
        });

        if (!_isRefreshing) {
          _isRefreshing = true;

          try {
            const newAccessToken = await refreshTokenLogic();
            _isRefreshing = false;
            _onRefreshed(newAccessToken);
            _failedQueue = [];
          } catch (_error) {
            _isRefreshing = false;
            _failedQueue = [];
            if (!onRefreshTokenFailed) return Promise.reject(_error);

            return onRefreshTokenFailed(_error);
          }
        }

        return retryOrigReq;
      }

      return Promise.reject(error);
    },
  );
};
