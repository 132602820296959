import NOTIFICATION_ACTION_TYPES from './notification.types';

const INITIAL_STATE = {
  read: [],
  unread: [],
  error: null,
  isLoading: false,
};

const notificationReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case NOTIFICATION_ACTION_TYPES.GET_NOTIFICATIONS_START:
      return { ...state, isLoading: true, error: null };
    case NOTIFICATION_ACTION_TYPES.GET_NOTIFICATIONS_SUCCESS:
      return { isLoading: false, error: null, read: payload.read, unread: payload.unread };
    case NOTIFICATION_ACTION_TYPES.GET_NOTIFICATIONS_FAILED:
      return { ...state, isLoading: false, error: payload };
    case NOTIFICATION_ACTION_TYPES.RESET_NOTIFICATIONS:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default notificationReducer;
