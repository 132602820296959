import { getAccessRole, getAccessToken, getRefreshToken } from '../../configs/cookies';
import { switchRole, switchRoleUrl } from '../../helpers/switchRole';

export const getUserProperty = () => {
  /*
   * General things that probably need in every services
   * 1. Access Token
   * 2. Role Key
   * 3. Role Value
   * 4. API url prefix
   */
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();
  const accessRoleId = getAccessRole();
  const accessRole = switchRole(accessRoleId);
  const accessRoleApiUrlPrefix = switchRoleUrl(accessRoleId);

  return {
    accessToken,
    refreshToken,
    accessRoleId,
    accessRole,
    accessRoleApiUrlPrefix,
  };
};
