import React from 'react';
import studentImg from 'assets/images/banner_siswa.svg';
import parentImg from 'assets/images/banner_orangtua.svg';
import teacherImg from 'assets/images/banner_guru.svg';
import verifyImg from 'assets/images/img_password.svg';

import styled from 'styled-components';
import { device } from 'utils/breakpoints';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  text-align: center;
  display: ${props => (props.isHideImageOnMobile ? 'none' : 'block')};

  > img {
    width: 200px;
    height: auto;

    @media ${device.tabletM} {
      width: 278px;
    }
    @media ${device.laptop} {
      width: 312px;
    }
  }

  @media ${device.laptop} {
    display: block;
  }
`;

/**
 *
 * @param {object} p
 * @param {boolean} p.isHideImageOnMobile
 * @param {'student'|'teacher'|'parent'|'verify'} p.type
 * @returns
 */
const RoleImage = ({ isHideImageOnMobile, type, ...rest }) => {
  return (
    <Wrapper isHideImageOnMobile={isHideImageOnMobile} {...rest}>
      {type === 'student' && <img src={studentImg} alt={type} />}
      {type === 'parent' && <img src={parentImg} alt={type} />}
      {type === 'teacher' && <img src={teacherImg} alt={type} />}
      {type === 'verify' && <img src={verifyImg} alt={type} />}
    </Wrapper>
  );
};

RoleImage.propTypes = {
  isHideImageOnMobile: PropTypes.bool,
  type: PropTypes.oneOf(['student', 'teacher', 'parent', 'verify']).isRequired,
};

RoleImage.defaultProps = {
  type: 'student',
  isHideImageOnMobile: false,
};

export default RoleImage;
