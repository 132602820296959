import Hashids from 'hashids';

const SALT = process.env.REACT_APP_HASH_KEY || 'skulid_salt';
const SALT_LENGTH = process.env.REACT_APP_HASH_LENGTH || 20;
const hashids = new Hashids(SALT, parseInt(SALT_LENGTH));

export const decodeHash = id => {
  return new Promise((resolve, reject) => {
    try {
      const v = hashids.decode(id);
      resolve(v);
    } catch (e) {
      reject(e);
    }
  });
};

export const encodeHash = id => {
  return new Promise((resolve, reject) => {
    try {
      const v = hashids.encode(id);
      resolve(v);
    } catch (e) {
      reject(e);
    }
  });
};

export const decodeHashNonPromise = (id, resultType = 'array') => {
  const v = hashids.decode(id);
  if (resultType === 'array') return v;
  if (resultType === 'str') return v.toString();
  if (resultType === 'int') return parseInt([v]);
  return [v];
};

export const encodeHashNonPromise = id => {
  const v = hashids.encode(id);
  return v;
};

/**
 * @param {string} id
 */
export const decodeId = id => hashids.decode(id);
export const encodeId = (...number) => hashids.encode(...number);
