export const NotFoundImage = () => {
  return (
    <svg
      width="400"
      height="202"
      viewBox="0 0 400 202"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M275.875 15.3085C276.692 13.3152 278.073 11.6189 279.84 10.4389C281.607 9.25894 283.678 8.64949 285.786 8.68934C293.331 8.68934 295.407 17.4947 295.407 17.4947C295.407 17.4947 295.925 15.3655 300.133 15.3085C304.341 15.2516 305.603 20.2426 305.603 20.2426C305.603 20.2426 309.577 18.1741 311.594 24.6149H265.217C265.217 24.6149 267.29 14.409 275.875 15.3085Z"
        fill="#D5E1E8"
      />
      <path
        d="M166.137 3.61146C165.652 2.52434 164.832 1.59925 163.783 0.95566C162.734 0.312067 161.505 -0.0204754 160.253 0.000976145C155.774 0.000976145 154.541 4.80392 154.541 4.80392C154.541 4.80392 154.234 3.64252 151.738 3.61146C149.242 3.58041 148.485 6.30276 148.485 6.30276C148.485 6.30276 146.123 5.17449 144.928 8.68767H172.464C172.464 8.68767 171.231 3.11668 166.137 3.61146Z"
        fill="#D5E1E8"
      />
      <path
        d="M187.839 114.045C54.744 114.045 0 201.45 0 201.45H365.217C365.217 201.45 308.561 114.045 187.839 114.045Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M313.044 153.793C248.609 153.793 223.188 199.403 223.188 199.403H400C400 199.403 371.488 153.793 313.044 153.793Z"
        fill="url(#paint1_linear)"
      />
      <ellipse cx="213.988" cy="163.791" rx="31.3791" ry="5.22504" fill="#E6EEF3" />
      <path
        d="M91.546 110.117H86.7151L85.5433 143.833C85.5236 144.399 85.9768 144.868 86.5427 144.868H91.7183C92.2843 144.868 92.7374 144.399 92.7177 143.833L91.546 110.117Z"
        fill="#D0DDE6"
      />
      <ellipse cx="88.4058" cy="93.4664" rx="13.0435" ry="13.7555" fill="#BACAD5" />
      <ellipse cx="80.4348" cy="107.222" rx="18.1159" ry="18.8233" fill="#BACAD5" />
      <ellipse cx="101.449" cy="106.498" rx="13.0435" ry="13.7555" fill="#BACAD5" />
      <path
        d="M337.05 143.59H333.561L332.725 167.653C332.705 168.218 333.158 168.688 333.724 168.688H336.887C337.453 168.688 337.906 168.218 337.887 167.653L337.05 143.59Z"
        fill="#D0DDE6"
      />
      <ellipse cx="334.058" cy="133.011" rx="9.42029" ry="9.93451" fill="#BACAD5" />
      <ellipse cx="332.609" cy="143.147" rx="9.42027" ry="9.93451" fill="#BACAD5" />
      <ellipse cx="322.464" cy="140.451" rx="7.97102" ry="8.68767" fill="#BACAD5" />
      <ellipse cx="344.203" cy="140.974" rx="9.4203" ry="9.93451" fill="#BACAD5" />
      <path
        d="M150.005 171.554C149.846 168.934 151.188 162.359 154.511 162.069C157.835 161.778 155.363 169.135 153.766 171.886C152.17 174.637 150.094 172.918 150.005 171.554Z"
        fill="#A6C0D8"
      />
      <path
        d="M154.541 170.322C155.061 169.134 155.48 167.904 155.795 166.646C154.271 166.212 152.805 165.599 151.425 164.82C150.834 166.097 150.424 167.45 150.207 168.84C151.538 169.62 153.012 170.124 154.541 170.322Z"
        fill="#8BAAC4"
      />
      <path
        d="M127.942 135.09C127.942 135.09 129.226 133.311 132.756 134.39C134.098 134.799 136.7 135.825 135.926 138.979C135.021 142.631 128.303 162.828 146.162 170.286C146.256 170.464 148.569 176.759 142.779 178.639C142.779 178.639 125.305 170.12 124.631 151.879C124.27 141.913 127.942 135.09 127.942 135.09Z"
        fill="#A6C0D8"
      />
      <path
        d="M146.162 170.287C146.197 170.287 154.033 169.053 159.231 171.122C159.231 171.122 161.992 180.317 156.416 185.747C156.416 185.747 152.584 185.878 142.779 178.633L146.162 170.287Z"
        fill="#8BAAC4"
      />
      <path
        d="M124.897 154.785C127.569 153.001 130.784 152.222 133.974 152.585C133.874 149.653 134.128 146.719 134.731 143.847C131.635 143.087 128.42 142.944 125.269 143.426C124.747 146.208 124.536 149.039 124.642 151.868C124.674 152.844 124.759 153.818 124.897 154.785Z"
        fill="#8BAAC4"
      />
      <path
        d="M134.494 172.61C134.941 171.769 135.47 170.974 136.073 170.238C137.252 168.824 138.649 167.609 140.213 166.64C138.094 164.778 136.459 162.428 135.446 159.793C132.65 160.563 130.024 161.854 127.706 163.599C129.442 166.967 131.738 170.014 134.494 172.61Z"
        fill="#8BAAC4"
      />
      <path
        d="M157.38 171.437C159.869 170.636 167.882 171.958 167.338 174.668C166.688 177.91 161.389 176.161 158.409 175.082C155.168 173.926 156.587 171.692 157.38 171.437Z"
        fill="#A6C0D8"
      />
      <path
        d="M159.402 175.432C160.58 175.852 161.791 176.175 163.021 176.398C162.676 175.658 162.515 174.844 162.554 174.027C162.592 173.19 162.836 172.376 163.263 171.656C162.099 171.381 160.911 171.224 159.715 171.188C159.465 171.675 159.301 172.203 159.23 172.747C159.108 173.644 159.166 174.557 159.402 175.432Z"
        fill="#8BAAC4"
      />
      <path
        d="M157.072 177.235C159.562 176.435 167.574 177.757 167.03 180.466C166.38 183.715 161.082 181.96 158.101 180.881C154.861 179.713 156.28 177.484 157.072 177.235Z"
        fill="#A6C0D8"
      />
      <path
        d="M159.101 181.229C160.277 181.653 161.488 181.977 162.72 182.195C162.374 181.454 162.214 180.64 162.253 179.824C162.296 178.997 162.54 178.193 162.962 177.482C161.798 177.21 160.609 177.053 159.414 177.014C159.165 177.502 159.001 178.029 158.929 178.573C158.81 179.461 158.868 180.364 159.101 181.229Z"
        fill="#8BAAC4"
      />
      <path
        d="M155.67 181.763C158.278 181.544 165.794 184.632 164.653 187.152C163.293 190.169 158.521 187.27 155.86 185.551C152.962 183.678 154.842 181.834 155.67 181.763Z"
        fill="#A6C0D8"
      />
      <path
        d="M156.735 186.115C157.79 186.799 158.899 187.394 160.052 187.893C159.874 187.094 159.899 186.262 160.123 185.475C160.352 184.677 160.77 183.947 161.341 183.347C160.273 182.822 159.156 182.403 158.006 182.096C157.655 182.516 157.377 182.993 157.184 183.507C156.866 184.338 156.714 185.225 156.735 186.115Z"
        fill="#8BAAC4"
      />
      <path
        d="M189.519 85.0509C189.519 85.0509 184.877 84.387 182.796 84.7605C182 89.4782 181.73 94.27 181.992 99.0475C181.992 99.0475 187.657 99.2253 188 99.0949C189.555 98.4902 192.216 90.7895 189.519 85.0509Z"
        fill="#94ADC1"
      />
      <path
        d="M185.9 99.1355C186.6 99.1686 187.302 99.1547 188 99.094C189.555 98.5012 192.24 90.7945 189.519 85.0441C189.519 85.0441 188.437 84.89 187.077 84.7773C188.275 89.2011 188.014 93.8948 186.332 98.1573C186.202 98.4893 186.06 98.8154 185.9 99.1355Z"
        fill="#7E94A1"
      />
      <path
        d="M198.17 75.3694C198.17 75.3694 192.482 75.6777 192.482 80.4321C192.482 80.4321 187.479 80.4973 188.449 86.1291C188.449 86.1291 184.688 86.2477 184.552 94.5413C184.416 102.835 182.228 134.189 183.369 137.817C183.369 137.817 186.781 144.676 211.352 145.05C228.022 145.299 241.067 145.69 244.804 135.185C245.183 134.124 247.986 89.5912 233.634 79.531C233.634 79.531 231.57 74.3557 227.939 75.4939C227.939 75.4939 226.277 72.2986 222.753 72.838C222.753 72.838 218.519 68.8661 209.258 69.6961C199.199 70.5735 198.17 75.3694 198.17 75.3694Z"
        fill="#A6C0D8"
      />
      <path
        d="M193.475 101.424C193.475 101.424 192.239 119.867 193.682 123.625C195.125 127.384 220.092 125.789 221.937 122.825C223.782 119.861 221.736 102.302 219.311 100.387C219.311 100.416 199.211 102.598 193.475 101.424Z"
        fill="#8BAAC4"
      />
      <path
        d="M166.197 104.566C166.197 104.566 146.322 103.671 140.627 106.226C134.595 108.929 131.378 122.7 139.166 130.49C144.134 135.458 149.32 134.954 166.646 136.104C166.646 136.104 170.277 134.96 169.774 120.098C169.562 113.713 169.822 107.269 166.197 104.566Z"
        fill="#A6C0D8"
      />
      <path
        d="M194.492 102.254C194.492 102.254 192.996 120.424 194.747 123.489C194.747 123.489 212.132 126.21 221.186 121.918C221.186 121.918 221.186 106.836 218.537 102.254C218.537 102.254 199.631 104.453 194.492 102.254Z"
        fill="#8BAAC4"
      />
      <path
        d="M244.846 135.161C243.832 138.136 241.714 140.605 238.932 142.056C240.582 136.086 238.483 102.046 236.62 91.399C234.757 80.7519 232.481 80.9357 232.481 80.9357L222.794 72.814C226.343 72.2805 227.981 75.4758 227.981 75.4758C231.617 74.3375 233.675 79.5129 233.675 79.5129C248.009 89.5671 245.206 134.1 244.846 135.161Z"
        fill="#8BAAC4"
      />
      <path
        d="M225.834 98.1524C225.835 98.3546 225.776 98.5525 225.665 98.7211C225.554 98.8897 225.395 99.0213 225.209 99.0992C225.023 99.1771 224.818 99.1978 224.62 99.1587C224.422 99.1195 224.24 99.0223 224.098 98.8793C223.955 98.7364 223.858 98.5541 223.819 98.3558C223.78 98.1575 223.801 97.952 223.878 97.7654C223.956 97.5788 224.087 97.4197 224.256 97.3081C224.424 97.1965 224.621 97.1375 224.823 97.1387C225.091 97.1402 225.347 97.2476 225.536 97.4373C225.725 97.6271 225.832 97.884 225.834 98.1524Z"
        fill="#8BAAC4"
      />
      <path
        d="M227.856 125.824C227.855 126.025 227.795 126.22 227.683 126.386C227.571 126.552 227.413 126.681 227.228 126.757C227.043 126.832 226.84 126.852 226.644 126.812C226.449 126.772 226.269 126.675 226.128 126.533C225.987 126.391 225.892 126.21 225.853 126.014C225.814 125.818 225.835 125.614 225.912 125.429C225.988 125.244 226.118 125.086 226.284 124.975C226.45 124.864 226.645 124.805 226.845 124.805C227.114 124.806 227.371 124.914 227.561 125.105C227.75 125.296 227.856 125.555 227.856 125.824Z"
        fill="#8BAAC4"
      />
      <path
        d="M189.803 127.295C189.803 127.495 189.744 127.691 189.633 127.858C189.522 128.025 189.364 128.155 189.179 128.232C188.994 128.308 188.791 128.328 188.595 128.289C188.399 128.25 188.218 128.154 188.077 128.012C187.936 127.87 187.839 127.689 187.8 127.493C187.761 127.296 187.781 127.092 187.858 126.907C187.934 126.722 188.064 126.563 188.23 126.452C188.396 126.341 188.592 126.281 188.792 126.281C189.06 126.281 189.317 126.388 189.507 126.578C189.697 126.768 189.803 127.026 189.803 127.295Z"
        fill="#8BAAC4"
      />
      <path
        d="M190.04 99.1661C190.598 99.1661 191.051 98.7123 191.051 98.1524C191.051 97.5925 190.598 97.1387 190.04 97.1387C189.481 97.1387 189.028 97.5925 189.028 98.1524C189.028 98.7123 189.481 99.1661 190.04 99.1661Z"
        fill="#8BAAC4"
      />
      <path
        d="M194.581 129.375C194.581 129.375 192.689 132.997 194.51 135.386C194.51 135.386 209.548 138.232 220.736 135.386C220.736 135.386 219.944 130.614 220.299 129.375C220.281 129.375 206.55 131.42 194.581 129.375Z"
        fill="#8BAAC4"
      />
      <path
        d="M220.281 129.375H221.842L222.221 135.031L220.719 135.386C220.719 135.386 219.909 131.023 220.281 129.375Z"
        fill="#8BAAC4"
      />
      <path
        d="M206.763 136.62C204.291 136.549 202.003 136.365 200.087 136.163C199.671 134.14 199.522 132.072 199.643 130.01C201.938 130.205 204.209 130.282 206.373 130.288C206.23 132.407 206.361 134.535 206.763 136.62Z"
        fill="#9EBBD3"
      />
      <path
        d="M220.725 135.392C218.444 135.953 216.119 136.316 213.776 136.477C213.348 134.37 213.201 132.215 213.339 130.069C217.478 129.802 220.281 129.381 220.281 129.381C219.932 130.614 220.725 135.392 220.725 135.392Z"
        fill="#9EBBD3"
      />
      <path
        d="M151.413 114.899C151.341 114.899 151.269 114.885 151.203 114.856C151.137 114.827 151.078 114.785 151.028 114.733L147.729 111.288C147.66 111.187 147.629 111.065 147.64 110.943C147.652 110.822 147.705 110.708 147.791 110.621C147.877 110.534 147.99 110.48 148.111 110.468C148.233 110.455 148.355 110.485 148.456 110.553L151.756 114.003C151.828 114.078 151.877 114.172 151.896 114.274C151.915 114.376 151.903 114.481 151.863 114.577C151.822 114.672 151.755 114.754 151.668 114.811C151.582 114.868 151.481 114.899 151.377 114.899H151.413Z"
        fill="#587893"
      />
      <path
        d="M147.865 114.816C147.794 114.816 147.723 114.802 147.658 114.773C147.593 114.745 147.535 114.703 147.487 114.65C147.438 114.601 147.399 114.543 147.372 114.479C147.346 114.415 147.332 114.346 147.332 114.276C147.332 114.207 147.346 114.138 147.372 114.074C147.399 114.01 147.438 113.952 147.487 113.903L150.981 110.542C151.03 110.493 151.088 110.454 151.152 110.427C151.216 110.4 151.285 110.387 151.354 110.387C151.423 110.387 151.492 110.4 151.556 110.427C151.62 110.454 151.678 110.493 151.727 110.542C151.826 110.642 151.882 110.777 151.882 110.918C151.882 111.059 151.826 111.195 151.727 111.295L148.255 114.644C148.205 114.697 148.145 114.74 148.078 114.77C148.011 114.799 147.938 114.815 147.865 114.816Z"
        fill="#587893"
      />
      <path
        d="M151.939 128.534C151.869 128.534 151.799 128.519 151.734 128.492C151.669 128.464 151.61 128.424 151.561 128.374L148.261 124.923C148.164 124.824 148.109 124.69 148.109 124.55C148.109 124.41 148.164 124.276 148.261 124.176C148.362 124.078 148.496 124.023 148.637 124.023C148.777 124.023 148.912 124.078 149.012 124.176L152.312 127.627C152.361 127.676 152.4 127.734 152.426 127.798C152.453 127.862 152.466 127.931 152.466 128C152.466 128.07 152.453 128.138 152.426 128.202C152.4 128.266 152.361 128.325 152.312 128.374C152.264 128.425 152.206 128.465 152.142 128.493C152.078 128.52 152.009 128.534 151.939 128.534Z"
        fill="#587893"
      />
      <path
        d="M148.456 128.451C148.35 128.453 148.246 128.422 148.158 128.363C148.069 128.304 148.001 128.22 147.961 128.121C147.921 128.022 147.912 127.914 147.935 127.81C147.958 127.706 148.012 127.611 148.09 127.539L151.585 124.177C151.686 124.081 151.821 124.028 151.961 124.031C152.101 124.035 152.233 124.094 152.33 124.195C152.426 124.296 152.478 124.432 152.475 124.572C152.472 124.712 152.413 124.845 152.312 124.942L148.823 128.303C148.725 128.399 148.593 128.452 148.456 128.451Z"
        fill="#587893"
      />
      <path
        d="M253.237 172.408C253.237 172.408 241.889 177.003 236.999 183.844C236.999 183.844 251.114 189.019 264.709 182.462C264.709 182.462 264.626 176.345 263.999 173.013L253.237 172.408Z"
        fill="#8BAAC4"
      />
      <path
        d="M264.709 182.457C251.108 189.02 236.999 183.844 236.999 183.844C237.318 183.584 247.566 175.1 255.236 172.521L263.988 173.019C264.626 176.351 264.709 182.457 264.709 182.457Z"
        fill="#A6C0D8"
      />
      <path
        d="M253.237 167.239C253.237 167.239 252.9 171.448 253.237 172.408C253.237 172.408 256.708 175.538 264 173.001C264 173.001 264.514 168.17 264.272 167.381C263.592 165.182 253.781 165.099 253.237 167.239Z"
        fill="#8BAAC4"
      />
      <path
        d="M264.272 167.405C264.319 168.994 262.882 169.017 260.192 169.071C256.975 169.142 253.385 168.608 253.237 167.251C253.781 165.093 263.592 165.182 264.272 167.405Z"
        fill="#A6C0D8"
      />
      <path
        d="M132.75 123.744L135.423 123.637C138.427 118.005 135.583 113.701 135.583 113.701H133.217C133.217 113.701 134.104 121.526 132.75 123.744Z"
        fill="#8BAAC4"
      />
      <path
        d="M133.217 113.701C133.217 113.701 131.23 113.607 130.964 117.543C130.639 122.44 131.644 124.23 132.738 123.744C133.832 123.258 134.548 121.71 134.4 118.07C134.252 114.431 133.749 113.79 133.217 113.701Z"
        fill="#94ADC1"
      />
      <path
        d="M181.459 147.908C180.478 149.052 174.653 153.735 175.712 157.582C176.894 161.946 189.188 165.473 189.188 165.473L184.233 174.46C184.233 174.46 163.033 171.093 165.138 156.675C165.138 156.675 166.912 147.451 171.052 143.781C172.465 142.525 175.901 142.43 177.769 142.963C180.531 143.734 182.648 146.514 181.459 147.908Z"
        fill="#A6C0D8"
      />
      <path
        d="M189.188 165.474C189.188 165.474 195.693 163.938 199.365 164.602C200.574 165.524 201.563 166.704 202.261 168.056C202.958 169.409 203.347 170.9 203.398 172.421C203.398 172.421 196.988 186.376 185.51 192.749C185.51 192.749 182.08 187.011 184.103 175.166C185.445 167.317 189.188 165.474 189.188 165.474Z"
        fill="#A6C0D8"
      />
      <path
        d="M203.398 172.421C203.398 172.421 196.988 186.376 185.51 192.749C184.327 174.371 199.365 164.602 199.365 164.602C200.574 165.523 201.563 166.703 202.26 168.056C202.958 169.408 203.346 170.899 203.398 172.421Z"
        fill="#8BAAC4"
      />
      <path
        d="M179.797 149.561C178.52 150.835 176.9 152.608 176.09 154.452C175.769 154.359 175.443 154.284 175.114 154.226C172.885 153.922 170.617 154.246 168.562 155.163C167.37 155.666 166.22 156.26 165.12 156.942C165.126 156.842 165.138 156.743 165.156 156.645C165.734 153.898 166.598 151.22 167.734 148.654C169.828 147.775 172.111 147.44 174.369 147.682C176.294 147.901 178.147 148.543 179.797 149.561Z"
        fill="#8BAAC4"
      />
      <path
        d="M183.192 163.291C182.151 164.112 181.232 165.076 180.46 166.155C179.071 168.102 178.187 170.366 177.888 172.741C174.41 171.472 170.283 169.38 167.651 166.096C167.988 165.686 168.355 165.295 168.692 164.91C170.359 163.098 172.318 161.581 174.487 160.422C175.246 160.013 176.038 159.666 176.853 159.385C178.272 160.885 180.803 162.254 183.192 163.291Z"
        fill="#8BAAC4"
      />
      <path
        d="M218.868 198.551C218.868 198.551 216.727 170.066 228.507 146.857C228.507 146.857 232.516 144.278 237.696 149.424C237.696 149.424 227.005 174.915 230.127 197.354L218.868 198.551Z"
        fill="#A6C0D8"
      />
      <path
        d="M218.868 198.551C218.868 198.551 218.72 194.633 224.06 194.401C227.608 194.265 229.583 195.231 230.127 197.33C230.671 199.428 228.797 201.308 225.172 201.693C221.783 202.078 219.217 200.97 218.868 198.551Z"
        fill="#8BAAC4"
      />
      <path
        d="M230.594 175.704C230.09 178.979 229.766 182.28 229.624 185.592C225.947 184.773 222.096 185.39 218.856 187.317C219.016 183.914 219.335 179.913 219.95 175.585C222.229 174.868 224.635 174.652 227.005 174.951C228.221 175.097 229.422 175.349 230.594 175.704Z"
        fill="#8BAAC4"
      />
      <path
        d="M234.982 157.067C234.13 159.734 233.208 162.995 232.344 166.629C229.676 165.761 226.862 165.432 224.066 165.662C223.268 165.725 222.477 165.854 221.7 166.048C222.476 162.665 223.44 159.328 224.586 156.053C228.084 155.642 231.629 155.987 234.982 157.067Z"
        fill="#8BAAC4"
      />
      <path
        d="M233.61 79.4365C233.51 79.5788 233.397 79.7211 233.285 79.8693C227.46 87.4989 219.998 92.4608 206.87 93.2552C193.742 94.0496 188.431 86.1413 188.431 86.1413C187.462 80.5036 192.464 80.4384 192.464 80.4384C192.464 75.6958 198.153 75.3698 198.153 75.3698C198.153 75.3698 199.182 70.5797 209.259 69.6787C218.525 68.8487 222.759 72.8147 222.759 72.8147C226.307 72.2811 227.945 75.4765 227.945 75.4765C231.34 74.4094 233.356 78.8022 233.61 79.4365Z"
        fill="#94ADC1"
      />
      <path
        d="M234.935 84.2086C234.935 84.2086 240.144 83.4616 242.48 83.8825C242.48 83.8825 240.328 89.7278 243.391 99.9243C243.391 99.9243 237.028 100.126 236.644 99.9777C234.899 99.3018 231.883 90.6526 234.935 84.2086Z"
        fill="#8BAAC4"
      />
      <path
        d="M242.48 83.8828C242.48 83.8828 244.846 84.3156 245.437 88.329C246.2 93.4154 245.585 99.9246 243.367 99.9246C239.878 99.9246 238.932 86.1296 242.48 83.8828Z"
        fill="#A6C0D8"
      />
      <path
        d="M239.003 100.025C238.215 100.065 237.424 100.049 236.638 99.9777C234.864 99.2959 231.907 90.6526 234.929 84.2086C234.929 84.2086 236.147 84.0307 237.673 83.9062C236.328 88.8732 236.623 94.1431 238.512 98.9284C238.666 99.2959 238.826 99.6635 239.003 100.025Z"
        fill="#94ADC1"
      />
      <path
        d="M271.285 121.1C268.919 119.962 262.332 118.764 260.836 121.693C259.34 124.621 267.045 125.143 270.179 124.693C273.313 124.242 272.538 121.699 271.285 121.1Z"
        fill="#A6C0D8"
      />
      <path
        d="M268.458 124.853C267.167 124.89 265.875 124.817 264.597 124.634C264.763 123.056 264.749 121.465 264.555 119.891C265.946 119.831 267.339 119.961 268.695 120.277C268.923 121.803 268.843 123.359 268.458 124.853Z"
        fill="#8BAAC4"
      />
      <path
        d="M245.91 86.8931C245.91 86.8931 243.793 87.4207 243.456 91.0962C243.326 92.5012 243.297 95.3053 246.502 95.7677C250.215 96.3012 271.415 97.6647 271.587 117.056C271.711 117.204 276.666 121.721 280.575 117.056C280.575 117.056 279.28 97.6232 262.675 90.1358C253.61 86.0454 245.91 86.8931 245.91 86.8931Z"
        fill="#A6C0D8"
      />
      <path
        d="M271.587 117.063C271.587 117.092 267.483 123.904 267.447 129.512C267.447 129.512 274.904 135.535 282.018 132.399C282.018 132.399 283.579 128.889 280.581 117.057H271.593L271.587 117.063Z"
        fill="#8BAAC4"
      />
      <path
        d="M265.265 91.4714C262.612 93.2852 260.685 95.9816 259.825 99.0832C257.151 97.8834 254.341 97.0155 251.457 96.4985C251.919 93.3346 252.998 90.2929 254.633 87.5469C257.4 88.1058 260.095 88.9748 262.669 90.1375C263.556 90.538 264.423 90.9832 265.265 91.4714Z"
        fill="#8BAAC4"
      />
      <path
        d="M278.121 107.097C277.173 107.199 276.238 107.398 275.33 107.69C273.582 108.242 271.935 109.073 270.451 110.15C269.533 107.484 267.98 105.083 265.927 103.155C267.69 100.846 269.873 98.8929 272.361 97.3984C274.82 100.278 276.768 103.558 278.121 107.097Z"
        fill="#8BAAC4"
      />
      <path
        d="M268.423 127.904C266.743 129.92 264.951 137.858 267.666 138.374C270.906 138.967 271.279 133.412 271.409 130.234C271.545 126.784 268.961 127.27 268.423 127.904Z"
        fill="#A6C0D8"
      />
      <path
        d="M271.356 131.291C271.304 132.545 271.148 133.792 270.889 135.02C270.334 134.416 269.639 133.958 268.866 133.686C268.086 133.418 267.252 133.343 266.436 133.467C266.619 132.288 266.918 131.13 267.329 130.01C267.873 129.963 268.421 130.009 268.949 130.147C269.819 130.365 270.637 130.753 271.356 131.291Z"
        fill="#8BAAC4"
      />
      <path
        d="M273.899 129.81C272.219 131.819 270.428 139.763 273.136 140.273C276.382 140.866 276.755 135.311 276.879 132.134C277.021 128.689 274.413 129.169 273.899 129.81Z"
        fill="#A6C0D8"
      />
      <path
        d="M276.832 133.188C276.777 134.441 276.621 135.688 276.364 136.916C275.81 136.313 275.115 135.857 274.342 135.589C273.562 135.316 272.728 135.239 271.912 135.363C272.095 134.185 272.394 133.027 272.805 131.907C273.348 131.862 273.896 131.909 274.425 132.043C275.294 132.264 276.112 132.652 276.832 133.188Z"
        fill="#8BAAC4"
      />
      <path
        d="M278.611 130.211C277.429 132.546 277.429 140.686 280.214 140.579C283.514 140.454 282.627 134.929 282.041 131.805C281.403 128.414 278.99 129.464 278.611 130.211Z"
        fill="#A6C0D8"
      />
      <path
        d="M282.231 132.851C282.458 134.083 282.582 135.333 282.604 136.586C281.928 136.125 281.151 135.836 280.339 135.744C279.518 135.657 278.688 135.771 277.92 136.076C277.836 134.89 277.867 133.699 278.015 132.519C278.535 132.348 279.082 132.269 279.629 132.288C280.525 132.301 281.41 132.492 282.231 132.851Z"
        fill="#8BAAC4"
      />
      <path
        d="M132.241 115.236C132.241 115.236 125.482 113.694 124.294 115.153C122.738 117.056 122.904 121.247 124.294 122.107C125.683 122.966 132.241 122.19 132.241 122.19C132.241 122.19 134.157 118.419 132.241 115.236Z"
        fill="#7093B0"
      />
      <path
        d="M125.216 117.016C125.171 117.016 125.126 117.005 125.087 116.983C125.047 116.961 125.014 116.928 124.991 116.889C124.968 116.851 124.955 116.806 124.954 116.761C124.953 116.715 124.964 116.671 124.986 116.631C125.051 116.5 125.772 115.35 129.166 116.038C129.23 116.056 129.285 116.098 129.319 116.155C129.354 116.212 129.366 116.279 129.354 116.345C129.341 116.41 129.305 116.468 129.251 116.508C129.198 116.548 129.132 116.566 129.066 116.56C126.056 115.967 125.453 116.886 125.447 116.892C125.422 116.93 125.387 116.961 125.347 116.983C125.307 117.004 125.262 117.016 125.216 117.016Z"
        fill="#F5F5F5"
      />
      <path
        d="M198.721 79.9222C198.721 79.9222 201.204 74.0533 210.293 74.2489C219.382 74.4446 222.357 77.8059 222.357 77.8059C222.357 77.8059 215.775 83.5207 211.813 83.7341C207.851 83.9475 198.721 79.9222 198.721 79.9222Z"
        fill="#7797B3"
      />
      <path
        d="M227.963 75.4699C224.722 81.0899 219.223 87.1189 208.567 87.1189C202.541 87.0738 196.771 84.6748 192.482 80.4318C192.482 75.6893 198.171 75.3632 198.171 75.3632C201.654 81.1254 208.85 80.7994 208.85 80.7994C217.183 80.6215 222.777 72.8081 222.777 72.8081C226.301 72.2746 227.963 75.4699 227.963 75.4699Z"
        fill="#8BAAC4"
      />
      <path
        d="M241.842 148.316C241.487 148.844 240.694 153.527 240.694 153.527H249.417L250.008 148.844L241.842 148.316Z"
        fill="#A6C0D8"
      />
      <path d="M241.398 156.883H249.068L249.417 153.527H240.694L241.398 156.883Z" fill="#8BAAC4" />
      <path
        d="M250.032 148.85L250.854 153.527L249.068 156.882L249.417 153.527L250.032 148.85Z"
        fill="#8BAAC4"
      />
      <path
        d="M153.7 146.988C153.443 147.913 153.245 148.854 153.109 149.804H157.84L158.177 147.273L153.7 146.988Z"
        fill="#A6C0D8"
      />
      <path d="M153.464 151.619H157.603L157.793 149.805H153.08L153.464 151.619Z" fill="#8BAAC4" />
      <path
        d="M158.129 147.279L158.573 149.805L157.603 151.619L157.792 149.805L158.129 147.279Z"
        fill="#8BAAC4"
      />
      <path
        d="M211.854 161.258C211.584 162.181 211.377 163.122 211.233 164.074H215.964L216.295 161.542L211.854 161.258Z"
        fill="#A6C0D8"
      />
      <path d="M211.612 165.888H215.757L215.946 164.074H211.233L211.612 165.888Z" fill="#8BAAC4" />
      <path
        d="M216.278 161.543L216.721 164.074L215.757 165.888L215.947 164.074L216.278 161.543Z"
        fill="#8BAAC4"
      />
      <path
        d="M166.126 104.11L166.28 109.333L163.104 110.038V113.998L166.41 114.395L166.705 117.081L163.329 117.721L163.104 122.227L166.96 122.962V126.453L163.134 127.177L163.104 131.143L166.522 132.429L166.368 134.634C166.323 135.222 166.482 135.806 166.817 136.29C167.152 136.774 167.643 137.127 168.207 137.29C171.507 138.245 177.97 139.626 180.194 136.65C181.731 134.599 183.979 118.344 180.975 106.214C180.786 105.442 180.363 104.747 179.765 104.225C179.167 103.703 178.422 103.379 177.633 103.298C173.547 102.883 166.025 102.361 166.126 104.11Z"
        fill="#8BAAC4"
      />
      <path
        d="M169.437 137.623L168.929 137.493C170.703 130.314 170.703 118.736 168.929 102.955C169.1 102.955 169.278 102.955 169.461 102.955C171.241 118.736 171.235 130.361 169.437 137.623Z"
        fill="#A6C0D8"
      />
      <path
        d="M205.412 53.8257C205.997 50.9058 201.609 48.2175 198.201 49.2929C198.757 47.2915 198.125 45.4694 195.159 44.5733C193.823 44.2111 192.411 44.2111 191.075 44.5733C193.448 39.9285 191.242 34.6414 183.911 33.5511C175.94 32.3563 167.095 36.2021 165.513 42.3853C159.025 36.1275 145.289 43.6175 149.343 50.8162C146.841 50.4279 144.263 51.8169 144.111 53.8854C143.587 53.6731 143.007 53.6376 142.46 53.7844C141.914 53.9312 141.433 54.2521 141.093 54.6966C140.752 55.141 140.572 55.6839 140.58 56.24C140.588 56.796 140.784 57.3337 141.137 57.7686C141.209 57.8515 141.299 57.9179 141.4 57.9631C141.501 58.0082 141.611 58.031 141.722 58.0299H209.374C209.519 58.0345 209.663 57.9954 209.785 57.9179C209.899 57.8605 209.987 57.7613 210.028 57.6416C210.758 55.476 207.921 53.4299 205.412 53.8257Z"
        fill="#EEF3F6"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="182.609"
          y1="114.045"
          x2="182.949"
          y2="192.978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E9F0F4" />
          <stop offset="1" stop-color="#FAFAFA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="311.594"
          y1="142.933"
          x2="311.888"
          y2="193.929"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E9F0F4" />
          <stop offset="1" stop-color="#FAFAFA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
