import { lazy } from 'react';
const ChooseRole = lazy(() => import('views/_auth/choose-role/choose-role.page'));
const ResetPasswordRoutes = lazy(() => import('views/_auth/resetPassword/reset-password.routes'));
const RegisterRoutes = lazy(() => import('views/_auth/register/register.routes'));
const Login = lazy(() => import('views/_auth/login/login'));

const authRoutes = [
  { path: '/', exact: true, component: ChooseRole, pageTitle: 'Web App' },
  {
    path: '/:slug/register',
    component: RegisterRoutes,
    pageTitle: 'Registrasi Pengguna Baru Lewat Web App',
  },
  { path: '/:slug/login/', exact: true, component: Login, pageTitle: 'Web App' },
  {
    path: '/:slug/reset-password/',
    component: ResetPasswordRoutes,
    pageTitle: 'Reset Password Lewat Web App',
  },
];

export default authRoutes;
