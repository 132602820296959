const USER_ACTION_TYPES = {
  SET_CURRENT_USER_IMAGE: 'user/SET_CURRENT_USER_IMAGE',
  SET_CURRENT_USER: 'user/SET_CURRENT_USER',

  SIGN_IN_START: 'user/SIGN_IN_START',
  SIGN_IN_SUCCESS: 'user/SIGN_IN_SUCCESS',
  SIGN_IN_FAILED: 'user/SIGN_IN_FAILED',

  // Not implement yet
  // SIGN_UP_START: 'user/SIGN_UP_START',
  // SIGN_UP_SUCCESS: 'user/SIGN_UP_SUCCESS',
  // SIGN_UP_FAILED: 'user/SIGN_UP_FAILED',

  SIGN_OUT_START: 'user/SIGN_OUT_START',
  SIGN_OUT_SUCCESS: 'user/SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILED: 'user/SIGN_OUT_FAILED',
};

export default USER_ACTION_TYPES;
