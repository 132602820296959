import AuthLayout from 'layouts/auth/auth.layout';
import React from 'react';
import { NotFoundImage } from './svg-images';
import { Wrapper, Title } from './error.styled';
import { withRouter } from 'react-router-dom';
import ButtonLinkPrimary from 'stories/components/forms/button-link-primary/button-link-primary.component';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMsg: null,
    };
  }

  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMsg: _error.message };
  }

  componentDidCatch(_error, _errorInfo) {
    // You can also log the error to an error reporting service
    // console.log('error =>', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <AuthLayout>
          <Wrapper>
            <NotFoundImage />
            <Title>
              <h1>Maaf, terjadi kesalahan pada sistem kami.</h1>
            </Title>
            <p>
              Harap
              <ButtonLinkPrimary
                label="muat ulang"
                type="button"
                onClick={() => window.location.reload()}
              />
              browser kamu, hubungi kami lewat
              <ButtonLinkPrimary label="WhatsApp" type="button" href={`http://tsel.me/waskulid`} />
              atau
              <ButtonLinkPrimary
                label="kembali ke halaman depan"
                type="button"
                onClick={() => window.location.replace('/')}
              />
            </p>
          </Wrapper>
        </AuthLayout>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
