// import CryptoJS from 'crypto-js';

const helperPhone = {
  groupPhone(phone) {
    if (!phone) return null;

    if (/^628/.test(phone)) {
      return phone.replace(/(\d{2})(\d{3})(\d{4})(\d{4})/, '+ ($1) $2-$3-$4');
    }

    if (/^08/.test(phone)) {
      return phone.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
    }

    return phone;
  },

  /**
   *
   * @param {number|string} phone
   * @param {'international' | 'national'} returnType
   * @returns {string}
   */
  convertPhone(phone, returnType) {
    const isLocalPhone = /^08/.test(phone);
    const isInternPhone = /^628/.test(phone);

    if (phone && returnType === 'international') {
      if (isLocalPhone) return phone.toString().replace(/^08/, '628');
      return phone.toString();
    }

    if (phone && returnType === 'national') {
      if (isInternPhone) return phone.toString().replace(/^628/, '08');
    }

    return phone?.toString() ?? '';
  },
};

const TSEL_PREFIX = ['0852', '0853', '0811', '0812', '0813', '0821', '0822'];
const BYU_PREFIX = ['0851'];

/**
 * @param {string|number} phone
 */
export const isTselNumber = phone => {
  const newPhone = helperPhone.convertPhone(phone, 'national');
  const prefixPhone = newPhone.slice(0, 4);

  return TSEL_PREFIX.includes(prefixPhone) || BYU_PREFIX.includes(prefixPhone);
};

// const encryptStringWithCBC256AndBase64 = (plainText, secretKey, iv) => {
//   const encrypted = CryptoJS.AES.encrypt(plainText, secretKey, {
//     iv: iv,
//     mode: CryptoJS.mode.CBC,
//     padding: CryptoJS.pad.Pkcs7,
//     keySize: 256 / 8,
//   });

//   const encryptedBase64 = encrypted.toString();
//   return encryptedBase64;
// };

// /**
//  * @param {string|number} phone
//  */
// export const getEncryptPhone = phone => {
//   const secretKey = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_BYU_SECRECT_KEY_AES);
//   const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV_BYU_SECRECT_KEY_AES);
//   const plaintext = CryptoJS.enc.Utf8.parse(phone);
//   const encryptedTextBase64 = encryptStringWithCBC256AndBase64(plaintext, secretKey, iv);
//   return encryptedTextBase64;
// };

/**
 * @param {string|number} phone
 */
export const getPulsaAndEvent = (phone, encrypted) => {
  const newPhone = helperPhone.convertPhone(phone, 'national');
  const prefixPhone = newPhone.slice(0, 4);

  if (BYU_PREFIX.includes(prefixPhone)) {
    const url =
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? `https://www.byu.id/v2/i-renew/input-nomor?mid=${encrypted}`
        : `https://camelot.byu.id/v2/i-renew/input-nomor?mid=${encrypted}`;
    return {
      url,
      eventName: 'web_pulsa_internet_byu',
    };
  }

  if (TSEL_PREFIX.includes(prefixPhone)) {
    return {
      url: 'https://www.telkomsel.com/shops/digital-product?utm_source=wec&utm_medium=skulbanner&utm_campaign=EPskul&utm_id=EPskul&embed=on',
      eventName: 'web_pulsa_internet_tsel',
    };
  }

  return {
    url: 'https://www.byu.id/',
    eventName: 'web_pulsa_internet_other',
  };
};

export const isPhoneByU = phone => {
  const newPhone = helperPhone.convertPhone(phone, 'national');
  const prefixPhone = newPhone.slice(0, 4);

  if (BYU_PREFIX.includes(prefixPhone)) {
    return true;
  }
  return false;
};

export default helperPhone;
