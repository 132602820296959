import { createAction } from 'utils/reducer/reducer.utils';
import USER_ACTION_TYPES from './user.types';

/**
 *
 * @param {object} param
 * @param {object} param.user
 * @param {string} param.userRole
 */
export const setCurrentUser = ({ user, userRole }) =>
  createAction(USER_ACTION_TYPES.SET_CURRENT_USER, { user, userRole });

/**
 *
 * @param {string} image
 */
export const setCurrentUserImage = image =>
  createAction(USER_ACTION_TYPES.SET_CURRENT_USER_IMAGE, image);

/**
 *
 * @param {'general' | 'google' | 'facebook'} signInType
 */
export const signInStart = signInType => createAction(USER_ACTION_TYPES.SIGN_IN_START, signInType);

/**
 *
 * @param {object} param
 * @param {object} param.user
 * @param {string} param.userRole
 */
export const signInSuccess = ({ user, userRole }) =>
  createAction(USER_ACTION_TYPES.SIGN_IN_SUCCESS, { user, userRole });
/**
 *
 * @param {object} param
 * @param {'general' | 'google' | 'facebook'} [param.signInType]
 * @param {string} [param.message]
 */
export const signInFailed = ({ signInType, message }) =>
  createAction(USER_ACTION_TYPES.SIGN_IN_FAILED, { signInType, message });

export const signOutStart = () => createAction(USER_ACTION_TYPES.SIGN_OUT_START);
export const signOutSuccess = () => createAction(USER_ACTION_TYPES.SIGN_OUT_SUCCESS);

/**
 *
 * @param {error} error
 */
export const signOutFailed = error => createAction(USER_ACTION_TYPES.SIGN_OUT_FAILED, error);
