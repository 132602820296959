import React from 'react';
import { Loader } from 'rsuite';
import styled from 'styled-components';
import skulLogo from '../assets/logos/logo.svg';

export const Loading = ({ text, style }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...style }}>
      {text && <Loader speed="slow" size="sm" content={text} className="skul-loader__pink" />}
      {!text && <Loader speed="slow" size="sm" className="skul-loader__pink" />}
    </div>
  );
};

const LoadingPageWrapper = styled.div`
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 120px;
  }
  > div {
    margin-top: 15px;
    display: flex;
    align-items: center;
  }
  h6 {
    margin-left: 8px;
    font-weight: 300;
    font-size: 13px;
  }
`;
export const LoadingPage = () => {
  return (
    <LoadingPageWrapper>
      <img src={skulLogo} alt="skul.id" />
      <div>
        <Loader speed="normal" size="sm" content="Memuat Halaman" className="skul-loader__pink" />
      </div>
    </LoadingPageWrapper>
  );
};
