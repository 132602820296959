import { get, isEmpty } from 'lodash';

/**
 *
 * @param {Error} error
 * @returns
 */
export const getError = error => {
  const { response, request } = error;
  if (navigator.onLine === false) {
    return {
      status: null,
      code: null,
      message: 'Gagal! Harap periksa koneksi internet kamu.',
    };
  }

  if (response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx

    let { status, data } = response;
    let code = null;
    let message = null;

    /**
     * Check for error pattern 1
     * If data is not empty & have error key inside
     * Example: data.error.code && data.error.message
     */
    if (!isEmpty(data) && 'error' in data) {
      code = get(data.error, 'code', null);
      message = get(data.error, 'message', null);
      const { error } = data;
      if (message === null) {
        message = error;
      }
    }

    /**
     * Check for error pattern 2
     * This error happens in response 400
     * If data is not empty & have message key inside
     * Example: data.message && data.data
     */
    if (!isEmpty(data) && 'message' in data) {
      code = get(data, 'code', null);
      // here we attach data to message, so we can get the message or data in controller, like message.message & message.data. data could be object, array or null, it depends on the api response
      message = data;
    }

    // in case server response with status or code 500
    if (status >= 500 || code >= 500) {
      // error otp
      // Anda sudah mencoba lebih dari maksimal request OTP, silahkan coba lagi dalam 20 detik
      // Anda sudah mencoba lebih dari maximal request OTP, silahkan coba lagi beberapa saat
      if (code === 505) return { status, code, message };

      // Terlalu banyak mencoba resend OTP, silahkan coba 5 menit lagi
      if (code === 506) return { status, code, message };

      // Anda sudah salah input pin OTP lebih dari 3 kali, Silahkan klik kirim ulang
      // Kode OTP sudah invalid, silahkan mencoba kembali
      if (code === 502) return { status, code, message };

      // Kode OTP yang anda masukkan salah
      if (code === 501) return { status, code, message };

      // Maaf, terdapat kendala di sistem kami silahkan mencoba beberapa saat lagi
      if (code === 504) return { status, code, message };

      return {
        status,
        code,
        // force replace api message
        message:
          'Mohon maaf, terjadi kesalahan pada sistem kami. Cobalah dalam beberapa saat lagi.',
      };
    }

    if (status === 401) {
      return {
        status,
        code,
        // force replace api message
        message: 'Sesi telah berakhir, silahkan login kembali.',
      };
    }

    return {
      status,
      code,
      message: !isEmpty(message) ? message : 'Mohon maaf, terjadi kesalahan pada sistem kami.',
    };
  }

  if (request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js

    return {
      status: null,
      code: null,
      message: 'Mohon maaf, terjadi kesalahan pada sistem kami. Cobalah dalam beberapa saat lagi.',
    };
  }

  return {
    status: null,
    code: null,
    message: error.message || 'Mohon maaf, terjadi kesalahan pada sistem kami.',
  };
};
