export const ERROR_CONSTANT = {
  REGISTER: {
    INVALID_TOKEN: 'INVALID_TOKEN',
  },
  RESET_PASSWORD: {
    INVALID_TOKEN: 'INVALID_TOKEN',
  },
  COMPLETE_DATA: {
    INVALID_TOKEN: 'INVALID_TOKEN',
  },
  EDIT_PHONE: {
    INVALID_TOKEN: 'INVALID_TOKEN',
    WARNING_TSEL_ONLY: 'WARNING_TSEL_ONLY',
  },
  CONNECT_SCHOOL: {
    WARNING_TSEL_ONLY: 'WARNING_TSEL_ONLY',
  },
  PERMIT_CHECK: {
    WARNING_DATA_EXIST: 'WARNING_DATA_EXIST',
    INVALID_REQUEST: 'INVALID_REQUEST',
  },
};
