import React from 'react';
import { Route, Switch } from 'react-router-dom';

import authRoutes from 'routes/auth.routes';
import linkAjaRoutes from 'routes/link-aja.routes';
import parentRoutes from 'routes/parent.routes';
import commonRoutes from 'routes/common.routes';
import teacherRoutes from 'routes/teacher.routes';
import studentRoutes from 'routes/student.routes';
import { LoadingPage } from 'components/Loading';
import { createGroupRoute } from 'utils/route/create-group-route.utils';
import ProtectedRoute from 'utils/route/protected-route.utils';
import GuestRoute from 'utils/route/guest-route.utils';
import StudentRoute from 'utils/route/student-route.utils';
import TeacherRoute from 'utils/route/teacher-route.utils';
import ParentRoute from 'utils/route/parent-route.utils';

const SuccessDelete = React.lazy(() => import('views/setting/pages/SuccessDelete'));
const NotFound = React.lazy(() => import('views/error/404.page'));
const GetApps = React.lazy(() => import('views/setting/pages/OpenApps'));
const UnderConstruction = React.lazy(() => import('views/error/under-construction.page'));

// NOTE: we do not implement createBrowserRoutes and integrate to redux yet, might need in future
const Routes = () => {
  return (
    <React.Suspense fallback={<LoadingPage />}>
      <Switch>
        {createGroupRoute({
          routes: commonRoutes,
          routeComponent: ProtectedRoute,
          closeEnvPage: UnderConstruction,
        })}

        {createGroupRoute({
          routes: linkAjaRoutes,
          routeComponent: ProtectedRoute,
          closeEnvPage: UnderConstruction,
        })}

        {createGroupRoute({
          routes: studentRoutes,
          routeComponent: StudentRoute,
          closeEnvPage: UnderConstruction,
        })}

        {createGroupRoute({
          routes: authRoutes,
          routeComponent: GuestRoute,
          closeEnvPage: UnderConstruction,
        })}

        {createGroupRoute({
          routes: teacherRoutes,
          routeComponent: TeacherRoute,
          closeEnvPage: UnderConstruction,
        })}

        {createGroupRoute({
          routes: parentRoutes,
          routeComponent: ParentRoute,
          closeEnvPage: UnderConstruction,
        })}

        {/* down below is normal route */}
        <Route path="/success/delete" exact component={SuccessDelete} />
        <Route path="/apps" exact component={GetApps} />
        <Route path="/beasiswaku" exact component={GetApps} />
        <Route component={NotFound} />
      </Switch>
    </React.Suspense>
  );
};

export default Routes;
