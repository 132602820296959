// import { compose, createStore, applyMiddleware } from 'redux';
// import logger from 'redux-logger';
// import rootReducer from './root-reducer';
// import thunk from 'redux-thunk';

// const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

// // enable logger if not in production mode
// const middleWares = [ENVIRONMENT !== 'production' && logger, thunk].filter(Boolean);

// // enable redux devtools if not in production mode
// const composeEnhancer =
//   (ENVIRONMENT !== 'production' && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
//   compose;

// const composeEnhancers = composeEnhancer(applyMiddleware(...middleWares));

// const store = createStore(rootReducer, composeEnhancers);

// export default store;

// src/redux/store.js

import { compose, createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import rootReducer from './root-reducer';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

const persistConfig = {
  key: 'host', // Persist only the host slice
  storage,
  whitelist: ['host'], // Persist only the host reducer
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleWares = [ENVIRONMENT !== 'production' && logger, thunk].filter(Boolean);
const composeEnhancer =
  (ENVIRONMENT !== 'production' && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(persistedReducer, composeEnhancer(applyMiddleware(...middleWares)));
export const persistor = persistStore(store);

export default store;
