import React from 'react';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';

const P = styled.p`
  font-size: 12px;
  color: #424d55;
  text-align: center;
  margin-bottom: 0;
  z-index: 1;

  @media ${device.tablet} {
    display: block;
    font-size: 13px;
  }
`;

export default function Copyright({ ...rest }) {
  return (
    <P {...rest}>
      Copyright {new Date().getFullYear()} PT Kuncie Pintar Nusantara All Rights Reserved
    </P>
  );
}
