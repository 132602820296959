import { combineReducers } from 'redux';
import preferenceReducer from './preferences/preferences.reducer';
import notificationReducer from './notification/notification.reducer';
import userReducer from './user/user.reducer';
import resetPasswordReducer from './reset-password/reset-password.reducer';
import chatReducer from './chat/chat.reducer';
import hostReducer from './get-config-firebase/host.reducer';

const rootReducer = combineReducers({
  preferences: preferenceReducer,
  notification: notificationReducer,
  user: userReducer,
  resetPassword: resetPasswordReducer,
  chats: chatReducer,
  host: hostReducer,
});

export default rootReducer;
