import { Cookies } from 'react-cookie';

const expires = new Date();
expires.setDate(expires.getDate() + 14);

const cookies = new Cookies();
const options = {
  path: '/',
  expires,
  sameSite: 'strict',
};

/**
 * used to set access token in cookies
 * @param {string} access_token
 * @returns void
 */
export const setAccessToken = access_token => {
  if (!access_token) return;
  cookies.set('access_token', access_token, options);
};

/**
 * used to set role code in cookies
 * @param {string | number} access_role  2 | 3 | 4 | 5
 * @returns void
 */
export const setAccessRole = access_role => {
  if (!access_role) return;
  cookies.set('access_role', access_role, options);
};

/**
 * used to set type in cookies
 * @param {string} type flutter
 * @returns void
 */
export const setTypeFlutter = type => {
  if (!type) return;
  cookies.set('type_from', type, options);
};

/**
 * used to set refresh token in cookies
 * @param {string} refresh_token
 * @returns void
 */
export const setRefreshToken = refresh_token => {
  if (!refresh_token) return;
  cookies.set('refresh_token', refresh_token, options);
};

/**
 *
 * @returns string | null | undefined
 */
export const getAccessToken = () => cookies.get('access_token');
/**
 *
 * @returns string | null | undefined
 */
export const getAccessRole = () => cookies.get('access_role');
/**
 *
 * @returns string | null | undefined
 */
export const getTypeFlutter = () => cookies.get('type_from');
/**
 *
 * @returns string | null | undefined
 */
export const getRefreshToken = () => cookies.get('refresh_token');

/**
 *
 * @returns void
 */
export const clearAccessToken = () => cookies.remove('access_token', { path: '/' });
/**
 *
 * @returns void
 */
export const clearAccessRole = () => cookies.remove('access_role', { path: '/' });
/**
 *
 * @returns void
 */
export const clearRefreshToken = () => cookies.remove('refresh_token', { path: '/' });
/**
 *
 * @returns void
 */
export const clearTypeFlutter = () => cookies.remove('type_from', { path: '/' });

/**
 *
 * @returns void
 */
export const clearCookies = () => {
  clearAccessToken();
  clearRefreshToken();
  clearAccessRole();
  clearTypeFlutter();
};
