import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { LoadingPage } from 'components/Loading';
import { USER_ROLE } from 'helpers/switchRole';
import { selectUserReducer } from 'store/user/user.selector';
import { setCurrentUserAsync } from 'store/user/user.thunk';

const StudentRoute = ({ component: Component, pageTitle, ...rest }) => {
  const { isAuthenticated, isAuthLoading, currentUser, role } = useSelector(selectUserReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) dispatch(setCurrentUserAsync());
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (pageTitle) return (document.title = 'Skul.id | ' + pageTitle);
    document.title = 'Skul.id';
  }, [pageTitle]);

  return (
    <Route
      {...rest}
      render={props => {
        // this one is only for tugasku
        const query = new URLSearchParams(props.location.search);
        const token = query.get('token') === null ? '' : query.get('token');
        const roleUrl = query.get('role') === null ? '' : query.get('role');

        if (isAuthLoading) {
          return <LoadingPage />;
        }

        if (!isAuthLoading && isAuthenticated && !isEmpty(currentUser)) {
          if (role !== USER_ROLE['student'].value) return <Redirect to={{ pathname: '/' }} />;

          // if is_need_complete_data true and is not in complete-data routes
          // used to avoid infinite loop
          if (currentUser.is_need_complete_data && !props.match.url.includes('complete-data')) {
            return <Redirect to={{ pathname: '/complete-data/request-otp' }} />;
          }

          return <Component {...props} />;
        }

        // this one is only for tugasku
        if (props.location.pathname.includes('tugasku') && !isEmpty(token) && !isEmpty(roleUrl)) {
          if (roleUrl !== USER_ROLE['student'].value) return <Redirect to={{ pathname: '/' }} />;
          return <Component {...props} />;
        }

        if (!isAuthLoading && !isAuthenticated) {
          return <Redirect to={{ pathname: '/student/login', state: { from: props.location } }} />;
        }
      }}
    />
  );
};

export default StudentRoute;
