import React from 'react';
import styled from 'styled-components';
import { device, size } from 'utils/breakpoints';
import Copyright from 'stories/components/data-display/copyright/copyright.component';
import DownloadApp from 'stories/components/data-display/download-app/download-app.component';
import { Toaster } from 'react-hot-toast';
import HeaderBar from './component/header/header-bar.component';
import RoleImage from './component/role-image/role-image.component';
import { ReactComponent as YoutubeIC } from 'assets/icons/youtube-icon.svg';
import { ReactComponent as InstaIC } from 'assets/icons/instagram-icon.svg';
import { ReactComponent as FBIC } from 'assets/icons/facebook-icon.svg';
import flowBottom from 'assets/images/flowy_bottom.svg';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;

  margin: 0 auto;

  @media ${device.laptop} {
    max-width: ${size.laptop};
  }
`;

const Main = styled.main`
  margin: 0 auto;
  margin-bottom: 56px;

  @media ${device.laptop} {
    max-width: ${size.laptop};
    margin-bottom: 85px;
  }
`;

const Sosmed = styled.div`
  margin: 0 auto;
  margin-bottom: 56px;
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 5px;
  background: #4db9da;
  padding: 10px;
`;

const SosmedItem = styled.button`
  cursor: pointer;
  background: transparent;
  color: white;
  display: flex;
  align-items: center;
`;

const ImgFlowBot = styled.img`
  display: block;
  width: 200px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  @media ${device.laptop} {
    max-width: ${size.laptop};
    width: 280px;
  }
`;

export default function AuthLayout({ children, isUseToaster }) {
  const { path } = useRouteMatch();
  const onClickHandler = sosmed => {
    if (sosmed == 'youtube') {
      window.open('https://www.youtube.com/@skuldotid6051', '_blank');
    }
    if (sosmed == 'insta') {
      window.open('https://www.instagram.com/skul.id/', '_blank');
    }
    if (sosmed == 'fb') {
      window.open('https://www.facebook.com/skuldotid/', '_blank');
    }
  };

  return (
    <>
      {isUseToaster && <Toaster />}
      <div style={{ padding: 16, position: 'relative' }}>
        <HeaderBar />

        <Main>{children}</Main>

        <Footer>
          <DownloadApp />
          <Sosmed>
            <SosmedItem style={{ marginTop: '2px' }} onClick={() => onClickHandler('youtube')}>
              <YoutubeIC style={{ width: '30px', marginRight: '5px' }} />
              <p style={{ paddingTop: '1px' }}>Skuldotid</p>
            </SosmedItem>
            <SosmedItem
              style={{ marginRight: '1.7rem', marginLeft: '1.9rem', marginTop: '1px' }}
              onClick={() => onClickHandler('insta')}
            >
              <InstaIC style={{ width: '30px', marginRight: '5px', marginTop: '3px' }} />
              <p style={{ marginTop: '2px' }}>@skul.id</p>
            </SosmedItem>
            <SosmedItem onClick={() => onClickHandler('fb')} style={{ marginTop: '2.6px' }}>
              <FBIC style={{ width: '30px', marginRight: '5px' }} />
              <p style={{ marginTop: '1px' }}>Skul.id</p>
            </SosmedItem>
          </Sosmed>
          <Copyright />
        </Footer>
        {path === '/' && <ImgFlowBot src={flowBottom} alt="image-bottom" />}
      </div>
    </>
  );
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 32px;

  @media ${device.tabletM} {
    max-width: ${size.mobileL};
    margin: 0 auto;
  }

  @media ${device.laptop} {
    max-width: ${size.tablet};
    flex-direction: row;
    gap: 72px;
  }
`;

const FormWrapper = styled.div`
  @media ${device.laptop} {
    max-width: ${size.mobileL};
    flex-grow: 1;
  }
`;

export const AuthLayoutOne = ({ children, isUseToaster, roleImageType }) => {
  return (
    <>
      {isUseToaster && <Toaster />}
      <div style={{ padding: 16 }}>
        <HeaderBar />

        <Main>
          <ContentWrapper>
            <RoleImage type={roleImageType} />
            <FormWrapper>{children}</FormWrapper>
          </ContentWrapper>
        </Main>

        <Footer>
          <DownloadApp />
          <Copyright />
        </Footer>
      </div>
    </>
  );
};

export const AuthLayoutTwo = ({ children, isUseToaster, roleImageType, isHideRoleImage }) => {
  return (
    <>
      {isUseToaster && <Toaster />}
      <div style={{ padding: 16 }}>
        <HeaderBar type={roleImageType} />

        <Main>
          <ContentWrapper>
            {!isHideRoleImage && <RoleImage isHideImageOnMobile type={roleImageType} />}
            <FormWrapper>{children}</FormWrapper>
          </ContentWrapper>
        </Main>

        <Footer>
          <DownloadApp />
          <Copyright />
        </Footer>
      </div>
    </>
  );
};
