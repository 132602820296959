import RESET_PASSWORD_ACTION_TYPES from './reset-password.types';

const INITIAL_STATE = {
  phone: '',
  otpChannel: null,
  otpToken: null,
};

const resetPasswordReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case RESET_PASSWORD_ACTION_TYPES.SET_PHONE_NUMBER:
      return { ...state, phone: payload };
    case RESET_PASSWORD_ACTION_TYPES.SET_OTP_CHANNEL:
      return { ...state, otpChannel: payload };
    case RESET_PASSWORD_ACTION_TYPES.SET_OTP_SECRET_TOKEN:
      return { ...state, otpToken: payload };
    case RESET_PASSWORD_ACTION_TYPES.RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default resetPasswordReducer;
