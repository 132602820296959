import CHAT_ACTION_TYPES from './chat.types';

const INITIAL_STATE = {
  chats: [],
  isHasMore: true,
  error: null,
  isLoading: false,
  isSearching: false,
};

const chatReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHAT_ACTION_TYPES.GET_CHATS_START:
      return { ...state, isLoading: true, error: null, isSearching: payload.isSearching };
    case CHAT_ACTION_TYPES.GET_CHATS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        chats: payload.chats,
        isHasMore: payload.isHasMore,
        isSearching: false,
      };
    case CHAT_ACTION_TYPES.GET_CHATS_FAILED:
      return { ...state, isLoading: false, error: payload, isSearching: false };
    case CHAT_ACTION_TYPES.RESET_CHATS:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default chatReducer;
