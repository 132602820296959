const PREFERENCES_ACTION_TYPES = {
  SET_IS_COMPLETED_TOUR_IN_HOME: 'preferences/SET_IS_COMPLETED_TOUR_IN_HOME',
  SET_IS_COMPLETED_TOUR_IN_HISTORY: 'preferences/SET_IS_COMPLETED_TOUR_IN_HISTORY',
  SET_IS_COMPLETED_TOUR_IN_TUGASKU_HOME: 'preferences/SET_IS_COMPLETED_TOUR_IN_TUGASKU_HOME',
  SET_IS_COMPLETED_TOUR_IN_TUGASKU_CLASS: 'preferences/SET_IS_COMPLETED_TOUR_IN_TUGASKU_CLASS',
  SET_SKIP_SOCMED: 'preferences/SET_SKIP_SOCMED',
  SET_PREFERENCES_FROM_LOCAL_STORAGE: 'preferences/SET_PREFERENCES_FROM_LOCAL_STORAGE',
  RESET_PREFERENCES: 'preferences/RESET_PREFERENCES',
};

export default PREFERENCES_ACTION_TYPES;
