import { createAction } from 'utils/reducer/reducer.utils';
import NOTIFICATION_ACTION_TYPES from './notification.types';

export const getNotificationsStart = () =>
  createAction(NOTIFICATION_ACTION_TYPES.GET_NOTIFICATIONS_START);
export const getNotificationsSuccess = ({ read, unread }) =>
  createAction(NOTIFICATION_ACTION_TYPES.GET_NOTIFICATIONS_SUCCESS, { read, unread });
export const getNotificationsFailed = error =>
  createAction(NOTIFICATION_ACTION_TYPES.GET_NOTIFICATIONS_FAILED, error);
export const resetNotifications = () => createAction(NOTIFICATION_ACTION_TYPES.RESET_NOTIFICATIONS);
