import styled from 'styled-components';
import { device, size } from 'utils/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: ${size.mobileL};
  margin: 0 auto;
  p {
    margin: 0 auto;
    font-weight: 300;
    font-size: 15px;
    text-align: center;
    width: 85%;

    button,
    a {
      font-weight: inherit;
      font-size: 15px;
      text-transform: lowercase;
      margin-left: 4px !important;
      margin-right: 4px !important;
      line-height: inherit;
    }
  }

  svg {
    width: 70%;
    height: auto;
  }

  @media ${device.laptop} {
    svg {
      width: 100%;
    }
    p {
      width: 100%;
    }
  }
`;

export const Title = styled.div`
  padding: 0 24px;
  margin-top: 16px;
  margin-bottom: 8px;
  h1 {
    line-height: normal;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
  }
`;
