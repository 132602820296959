import { lazy } from 'react';
const EditSocialMedia = lazy(() => import('views/_teacher/profileEdit/EditSocialMedia'));
const NoteDetail = lazy(() => import('../views/_teacher/attitudeNote/NoteDetail'));
const NoteEdit = lazy(() => import('../views/_teacher/attitudeNote/NoteEdit'));
const NoteCreate = lazy(() => import('../views/_teacher/attitudeNote/NoteCreate'));
const AttitudeNote = lazy(() => import('../views/_teacher/attitudeNote/AttitudeNote'));
const PermitBulkChooseStudent = lazy(() =>
  import('../views/_teacher/permitBulk/PermitBulkChooseStudent'),
);
const PermitBulkChooseClass = lazy(() =>
  import('../views/_teacher/permitBulk/PermitBulkChooseClass'),
);
const PermitBulkClassStudent = lazy(() =>
  import('../views/_teacher/permitBulk/PermitBulkClassStudent'),
);
const PermitBulk = lazy(() => import('../views/_teacher/permitBulk/PermitBulk'));
const Notification = lazy(() => import('../views/notification/notification-teacher.page'));
const TeacherAttendanceSummary = lazy(() =>
  import('../views/history/TeacherAttendanceSummaryDetails'),
);
const GradeDetail = lazy(() => import('../views/_teacher/grade/GradeDetails'));
const Grade = lazy(() => import('../views/_teacher/grade/Grade'));
const GradeChooseClass = lazy(() => import('../views/_teacher/grade/GradeChooseClass'));
const GradeChooseClassInput = lazy(() => import('../views/_teacher/grade/GradeChooseClassInput'));
const GradeChooseClassImportTugasku = lazy(() =>
  import('../views/_teacher/grade/GradeChooseClassImportTugasku'),
);
const GradeChooseStudent = lazy(() => import('../views/_teacher/grade/GradeChooseStudent'));
const GradeChooseComponents = lazy(() => import('../views/_teacher/grade/GradeChooseComponents'));
const GradeChooseStudentDetailList = lazy(() =>
  import('../views/_teacher/grade/GradeChooseStudentDetailList'),
);
const GradeChooseStudentDetailBy = lazy(() =>
  import('../views/_teacher/grade/GradeChooseStudentDetailBy'),
);
const GradeChooseStudentDetailProfile = lazy(() =>
  import('../views/_teacher/grade/GradeChooseStudentDetailProfile'),
);
const ClassStudent = lazy(() => import('../views/_teacher/clockInStudent/ClassStudent'));
const ChooseStudent = lazy(() => import('../views/_teacher/clockInStudent/ChooseStudent'));
const ChooseClass = lazy(() => import('../views/_teacher/clockInStudent/ChooseClass'));
const ClockInStudent = lazy(() => import('../views/_teacher/clockInStudent/ClockInStudent'));
const ClockInClass = lazy(() => import('../views/_teacher/clockInStudent/ClockInClass'));
const HistoryClassroomDetail = lazy(() => import('../views/history/HistoryClassroomDetail'));
const HistoryPermitDetail = lazy(() =>
  import('../views/_teacher/history-permit/history-permit-detail.page'),
);
const HistoryAttendanceDetail = lazy(() => import('../views/history/HistoryAttendanceDetail'));
const HistoryTeacher = lazy(() => import('../views/history/HistoryTeacher'));
const Beranda = lazy(() => import('../views/beranda/Beranda'));
const PointV2 = lazy(() => import('../views/coin/Point-v2'));
const Reward = lazy(() => import('../views/reward-koin/RewardList'));
const RewardDetail = lazy(() => import('../views/reward-koin/DetailReward'));
const ClaimList = lazy(() => import('../views/reward-koin/RewardClaimList'));
const TugasKu = lazy(() => import('../views/_teacher/tugasku/TugasKu'));
const TugasKuForm = lazy(() => import('../views/_teacher/tugasku/TugasKuForm'));
const TugasKuDetail = lazy(() => import('../views/_teacher/tugasku/TugasKuDetail'));
const TugasKuInfoClass = lazy(() => import('../views/_teacher/tugasku/TugasKuInfoClass'));
const TugasKuInfoHistory = lazy(() => import('../views/_teacher/tugasku/TugasKuInfoHistory'));
const TugasKuArchives = lazy(() => import('../views/_teacher/tugasku/TugasKuArchives'));
const TugasKuTopics = lazy(() => import('../views/_teacher/tugasku/TugasKuTopics'));
const TugasKuMembers = lazy(() => import('../views/_teacher/tugasku/TugasKuMembers'));
const TugasKuTasksForm = lazy(() => import('../views/_teacher/tugasku/TugasKuTasksForm'));
const TugasKuModulesForm = lazy(() => import('../views/_teacher/tugasku/TugasKuModulesForm'));
const TugasKuQuizzesForm = lazy(() => import('../views/_teacher/tugasku/TugasKuQuizzesForm'));
const TugasKuAnnouncesForm = lazy(() => import('../views/_teacher/tugasku/TugasKuAnnouncesForm'));
const TugasKuAnnouncesDetail = lazy(() =>
  import('../views/_teacher/tugasku/TugasKuAnnouncesDetail'),
);
const TugasKuTasksDetail = lazy(() => import('../views/_teacher/tugasku/TugasKuTasksDetail'));
const TugasKuModulesDetail = lazy(() => import('../views/_teacher/tugasku/TugasKuModulesDetail'));
const TugasKuQuizzesDetail = lazy(() => import('../views/_teacher/tugasku/TugasKuQuizzesDetail'));
const TugasKuQuizzesScore = lazy(() => import('../views/_teacher/tugasku/TugasKuQuizzesScore'));
const TugasKuTasksScore = lazy(() => import('../views/_teacher/tugasku/TugasKuTasksScore'));
const TugasKuTasksScoreDetail = lazy(() =>
  import('../views/_teacher/tugasku/TugasKuTasksScoreDetail'),
);
const TugasKuQuizzesScoreDetail = lazy(() =>
  import('../views/_teacher/tugasku/TugasKuQuizzesScoreDetail'),
);
const TugasKuStudentScore = lazy(() => import('../views/_teacher/tugasku/TugasKuStudentScore'));
const TugasKuTasksDetailFile = lazy(() =>
  import('../views/_teacher/tugasku/TugasKuTasksDetailFile'),
);
const TugasKuModulesDetailFile = lazy(() =>
  import('../views/_teacher/tugasku/TugasKuModulesDetailFile'),
);
const CreatePermit = lazy(() => import('../views/_teacher/permit-v2/create-permit.page'));
const EditPermit = lazy(() => import('../views/_teacher/permit-v2/edit-permit.page'));
const ScheduleTeacher = lazy(() => import('../views/schedule/Schedule'));
const SubjectEdit = lazy(() => import('../views/_teacher/subject-edit/subject-edit.page'));
const SchoolEmpty = lazy(() => import('../views/empty-school/empty-school.page'));
const SchoolSearch = lazy(() => import('../views/search-school/search-school.page'));
const SchoolConnect = lazy(() => import('../views/_teacher/connectSchool/connect-school.page'));
const EmailEdit = lazy(() => import('../views/email-edit/email-edit.page'));

const EditProfile = lazy(() => import('../views/_teacher/profileEdit/EditProfile'));
const SchoolMine = lazy(() => import('../views/_teacher/connectSchool/school-mine.page'));
const Profile = lazy(() => import('../views/_teacher/profile/profile-teacher.page'));
const FavoriteEdit = lazy(() => import('../views/favorite-edit/favorite-edit.page'));
const UnderConstruction = lazy(() => import('../views/error/under-construction.page'));
const Homeroom = lazy(() => import('../views/homeroom/Homeroom'));
const HomeroomClassList = lazy(() => import('views/homeroom/HomeroomClassList'));
const HomeroomAttendanceHistory = lazy(() => import('../views/homeroom/HomeroomAttendanceHistory'));
const HomeroomAttendanceDetail = lazy(() => import('../views/homeroom/HomeroomAttendanceDetail'));
const HomeroomPermitHistory = lazy(() => import('../views/homeroom/HomeroomPermitHistory'));
const HomeroomPermitDetail = lazy(() => import('../views/homeroom/HomeroomPermitDetail'));
const HomeroomRegister = lazy(() => import('../views/homeroom/HomeroomRegister'));
const HomeroomRequest = lazy(() => import('../views/homeroom/HomeroomRequest'));
const HomeroomStudentList = lazy(() => import('../views/homeroom/HomeroomStudentList'));
const HomeroomStudentDetail = lazy(() => import('../views/homeroom/HomeroomStudentDetail'));
const HomeroomHistory = lazy(() => import('../views/homeroom/HomeroomHistory'));
const Announcement = lazy(() => import('../views/homeroom/Announcement'));
const AnnouncementEdit = lazy(() => import('../views/homeroom/AnnouncementEdit'));
const AnnouncementDetail = lazy(() => import('../views/homeroom/AnnouncementDetail'));
const HomeroomAttitudeNote = lazy(() => import('../views/homeroom/HomeroomAttitudeNote'));
const HomeroomAttitudeNoteStudent = lazy(() =>
  import('../views/homeroom/HomeroomAttitudeNoteStudent'),
);
const HomeroomAttitudeNoteStudentEdit = lazy(() =>
  import('../views/homeroom/HomeroomAttitudeNoteStudentEdit'),
);
const HomeroomAttitudeNoteStudentDetail = lazy(() =>
  import('../views/homeroom/HomeroomAttitudeNoteStudentDetail'),
);
const Eraport = lazy(() => import('../views/_teacher/eraport/Eraport'));
const EraportDetail = lazy(() => import('../views/_teacher/eraport/EraportDetail'));
const EraportList = lazy(() => import('../views/_teacher/eraport/EraportList'));
const PhoneEdit = lazy(() => import('views/phone-edit/phone-edit.page'));
const OTPRequest = lazy(() => import('views/phone-edit/OTP-request.page'));
const OTPVerify = lazy(() => import('views/phone-edit/OTP-verify.page'));
const CompleteDataRoutes = lazy(() => import('views/complete-data/complete-data.routes'));
const MBGRoutes = lazy(() => import('views/mbg/mbg.routes'));

const StudentAttendanceRouter = lazy(() =>
  import('views/_teacher/student-attendance/attendance.routes'),
);

const prefix = '/teacher';
const teacherRoutes = [
  {
    path: `${prefix}/student-attendance`,
    exact: false,
    component: StudentAttendanceRouter,
    pageTitle: 'Beranda',
  },
  { path: `${prefix}/home`, exact: true, component: Beranda, pageTitle: 'Beranda' },
  {
    path: `${prefix}/koin`,
    exact: true,
    component: PointV2,
    pageTitle: 'Koin',
  },
  {
    path: `${prefix}/koin/gift`,
    exact: true,
    component: Reward,
    pageTitle: 'Hadiah Koin',
  },
  {
    path: `${prefix}/koin/gift/:uuid`,
    exact: true,
    component: RewardDetail,
    pageTitle: 'Hadiah Detail',
  },
  {
    path: `${prefix}/claim/list`,
    exact: true,
    component: ClaimList,
    pageTitle: 'Klaim Hadiah',
  },
  {
    path: `${prefix}/e-raport`,
    exact: true,
    component: Eraport,
    pageTitle: 'E-Rapor',
  },
  {
    path: `${prefix}/e-raport/list`,
    exact: true,
    component: EraportList,
    pageTitle: 'E-Rapor detail',
  },
  {
    path: `${prefix}/e-raport/detail`,
    exact: true,
    component: EraportDetail,
    pageTitle: 'E-Rapor detail',
  },
  {
    path: `${prefix}/e-raport/:uuid`,
    exact: true,
    component: EraportDetail,
    pageTitle: 'E-Rapor detail',
  },
  {
    path: `${prefix}/tugasku`,
    exact: true,
    component: TugasKu,
    pageTitle: 'Tugasku',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/new`,
    exact: true,
    component: TugasKuForm,
    pageTitle: 'Buat Tugasku Kelas',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/archives`,
    exact: true,
    component: TugasKuArchives,
    pageTitle: 'Tugasku Arsip',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash`,
    exact: true,
    component: TugasKuDetail,
    pageTitle: 'Tugasku Detail',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/notif`,
    exact: true,
    component: TugasKuInfoHistory,
    pageTitle: 'Tugasku Information History',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/info`,
    exact: true,
    component: TugasKuInfoClass,
    pageTitle: 'Tugasku Info Kelas',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/edit`,
    exact: true,
    component: TugasKuForm,
    pageTitle: 'Edit Tugasku Kelas',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/topics`,
    exact: true,
    component: TugasKuTopics,
    pageTitle: 'Tugasku Kelas Topik',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/members`,
    exact: true,
    component: TugasKuMembers,
    pageTitle: 'Tugasku Tambah Anggota',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/tasks`,
    exact: true,
    component: TugasKuTasksForm,
    pageTitle: 'Tugasku Tambah Tugas',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/tasks/:hashtask`,
    exact: true,
    component: TugasKuTasksDetail,
    pageTitle: 'Tugasku Detail Tugas',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/tasks/:hashtask/edit`,
    exact: true,
    component: TugasKuTasksForm,
    pageTitle: 'Tugasku Edit Tugas',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/modules`,
    exact: true,
    component: TugasKuModulesForm,
    pageTitle: 'Tugasku Tambah Materi',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/modules/:hashmodule`,
    exact: true,
    component: TugasKuModulesDetail,
    pageTitle: 'Tugasku Detail Materi',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/modules/:hashmodule/edit`,
    exact: true,
    component: TugasKuModulesForm,
    pageTitle: 'Tugasku Edit Materi',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/quizzes`,
    exact: true,
    component: TugasKuQuizzesForm,
    pageTitle: 'Tugasku Tambah Quiz',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/quizzes/:hashquiz`,
    exact: true,
    component: TugasKuQuizzesDetail,
    pageTitle: 'Tugasku Detail Quiz',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/quizzes/:hashquiz/students/:hashstudent`,
    exact: true,
    component: TugasKuQuizzesDetail,
    pageTitle: 'Tugasku Detail Quiz',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/quizzes/:hashquiz/edit`,
    exact: true,
    component: TugasKuQuizzesForm,
    pageTitle: 'Tugasku Edit Quiz',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/announces`,
    exact: true,
    component: TugasKuAnnouncesForm,
    pageTitle: 'Tugasku Tambah Pengumuman',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/announces/:hashannounce`,
    exact: true,
    component: TugasKuAnnouncesDetail,
    pageTitle: 'Tugasku Detail Pengumuman',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/announces/:hashannounce/edit`,
    exact: true,
    component: TugasKuAnnouncesForm,
    pageTitle: 'Tugasku Pengumuman Edit',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/quizzes/:hashquiz/score`,
    exact: true,
    component: TugasKuQuizzesScore,
    pageTitle: 'Tugasku Quiz Score',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/tasks/:hashtask/score/:hashtaskscore`,
    exact: true,
    component: TugasKuTasksScoreDetail,
    pageTitle: 'Tugasku Task Detail Score',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/quizzes/:hashquiz/score/:hashquizscore`,
    exact: true,
    component: TugasKuQuizzesScoreDetail,
    pageTitle: 'Tugasku Quiz Detail Score',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/tasks/:hashtask/score`,
    exact: true,
    component: TugasKuTasksScore,
    pageTitle: 'Tugasku Tasks Score',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/student-score`,
    exact: true,
    component: TugasKuStudentScore,
    pageTitle: 'Tugasku Student Score',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/tasks/:hashtask/file/:hashmedia`,
    exact: true,
    component: TugasKuTasksDetailFile,
    pageTitle: 'Tugasku Task Detail File',
    closeEnv: [],
  },
  {
    path: `${prefix}/tugasku/:hash/modules/:hashmodule/file/:hashmedia`,
    exact: true,
    component: TugasKuModulesDetailFile,
    pageTitle: 'Tugasku Task Detail File',
    closeEnv: [],
  },
  { path: `${prefix}/profile`, exact: true, component: Profile, pageTitle: 'Akun Saya' },
  { path: `${prefix}/profile/edit`, exact: true, component: EditProfile },
  { path: `${prefix}/profile/edit/favorite`, exact: true, component: FavoriteEdit },
  {
    path: `${prefix}/profile/edit/email`,
    exact: true,
    component: EmailEdit,
    pageTitle: 'Edit email',
  },
  {
    path: `${prefix}/profile/edit/phone`,
    exact: true,
    component: PhoneEdit,
    pageTitle: 'Edit phone',
  },
  {
    path: `${prefix}/profile/edit/phone/request-otp`,
    exact: true,
    component: OTPRequest,
  },
  {
    path: `${prefix}/profile/edit/phone/verify-otp`,
    exact: true,
    component: OTPVerify,
  },
  {
    path: `${prefix}/profile/edit/social-media`,
    exact: true,
    component: EditSocialMedia,
    pageTitle: 'Tambah akun social media',
  },
  { path: `${prefix}/profile/edit/subjects`, exact: true, component: SubjectEdit },
  { path: `${prefix}/profile/my-school`, exact: true, component: SchoolMine },
  { path: `${prefix}/profile/empty-school`, exact: true, component: SchoolEmpty },
  {
    path: `${prefix}/profile/connect-school/:newSchoolUuid`,
    exact: true,
    component: SchoolConnect,
  },
  { path: `${prefix}/profile/search-school`, exact: true, component: SchoolSearch },
  { path: `${prefix}/schedule`, exact: true, component: ScheduleTeacher },

  { path: `${prefix}/history/`, exact: true, component: HistoryTeacher },
  { path: `${prefix}/history/summary`, exact: true, component: TeacherAttendanceSummary },
  { path: `${prefix}/history/attendance/:slug`, exact: true, component: HistoryAttendanceDetail },
  { path: `${prefix}/history/permit/:permitHashId`, exact: true, component: HistoryPermitDetail },
  { path: `${prefix}/history/classroom`, exact: true, component: HistoryClassroomDetail },

  { path: `${prefix}/permit/`, exact: true, component: CreatePermit },
  { path: `${prefix}/permit/edit/:permitHashId`, exact: true, component: EditPermit },
  {
    path: `${prefix}/permit/class-student`,
    exact: true,
    component: PermitBulkClassStudent,
    closeEnv: [],
  },
  {
    path: `${prefix}/permit/choose-student`,
    exact: true,
    component: PermitBulkChooseStudent,
    closeEnv: [],
  },
  {
    path: `${prefix}/permit/choose-class`,
    exact: true,
    component: PermitBulkChooseClass,
    closeEnv: [],
  },
  { path: `${prefix}/permit/bulk`, exact: true, component: PermitBulk, closeEnv: [] },

  { path: `${prefix}/attendance/class-student`, exact: true, component: ClassStudent },
  { path: `${prefix}/attendance/choose-student`, exact: true, component: ChooseStudent },
  { path: `${prefix}/attendance/choose-class`, exact: true, component: ChooseClass },
  { path: `${prefix}/attendance/clock-in-student`, exact: true, component: ClockInStudent },
  { path: `${prefix}/attendance/clock-in-class`, exact: true, component: ClockInClass },

  { path: `${prefix}/grade/choose-class`, exact: true, component: GradeChooseClass },
  { path: `${prefix}/grade/class-input-score`, exact: true, component: GradeChooseClassInput },
  {
    path: `${prefix}/grade/detail-list-score`,
    exact: true,
    component: GradeChooseStudentDetailList,
  },
  {
    path: `${prefix}/grade/class-import-tugasku-score`,
    exact: true,
    component: GradeChooseClassImportTugasku,
  },
  { path: `${prefix}/grade/choose-student`, exact: true, component: GradeChooseStudent },
  {
    path: `${prefix}/grade/components`,
    exact: true,
    component: GradeChooseComponents,
  },
  {
    path: `${prefix}/grade/student-list`,
    exact: true,
    component: GradeChooseStudentDetailBy,
  },
  {
    path: `${prefix}/grade/student-detail-profile`,
    exact: true,
    component: GradeChooseStudentDetailProfile,
  },
  { path: `${prefix}/grade`, exact: true, component: Grade },
  { path: `${prefix}/grade/detail/:slug`, exact: true, component: GradeDetail },
  {
    path: `${prefix}/notifications`,
    exact: true,
    component: Notification,
    pageTitle: 'Notifikasi',
  },

  { path: `${prefix}/attitude-note`, exact: true, component: AttitudeNote },
  { path: `${prefix}/attitude-note/create`, exact: true, component: NoteCreate },
  { path: `${prefix}/attitude-note/:hashNoteId`, exact: true, component: NoteDetail },
  { path: `${prefix}/attitude-note/:hashNoteId/edit`, exact: true, component: NoteEdit },

  { path: `${prefix}/homeroom/`, exact: true, component: Homeroom, closeEnv: [] },
  {
    path: `${prefix}/homeroom/edit`,
    exact: true,
    component: HomeroomClassList,
    closeEnv: [],
  },
  {
    path: `${prefix}/homeroom/register`,
    exact: true,
    component: HomeroomRegister,
    closeEnv: [],
  },
  {
    path: `${prefix}/homeroom/students`,
    exact: true,
    component: HomeroomStudentList,
    closeEnv: [],
  },
  {
    path: `${prefix}/homeroom/students/:studentHashId`,
    exact: true,
    component: HomeroomStudentDetail,
    closeEnv: [],
  },
  {
    path: `${prefix}/homeroom/history`,
    exact: true,
    component: HomeroomHistory,
    closeEnv: [],
  },
  {
    path: `${prefix}/homeroom/history/permit`,
    exact: true,
    component: HomeroomPermitHistory,
    closeEnv: [],
  },
  {
    path: `${prefix}/homeroom/requests`,
    exact: true,
    component: HomeroomRequest,
    closeEnv: [],
  },
  {
    path: `${prefix}/homeroom/request/:hashAttendanceId/attendance`,
    exact: true,
    component: HomeroomAttendanceDetail,
    closeEnv: [],
  },
  {
    path: `${prefix}/homeroom/request/:hashPermitId/permit`,
    exact: true,
    component: HomeroomPermitDetail,
    closeEnv: [],
  },
  {
    path: `${prefix}/homeroom/history/attendance`,
    exact: true,
    component: HomeroomAttendanceHistory,
    closeEnv: [],
  },
  {
    path: `${prefix}/homeroom/announcement`,
    exact: true,
    component: Announcement,
    closeEnv: [],
  },
  {
    path: `${prefix}/homeroom/announcement/:uuid`,
    exact: true,
    component: AnnouncementDetail,
    closeEnv: [],
  },
  {
    path: `${prefix}/homeroom/announcement/:uuid/edit`,
    exact: true,
    component: AnnouncementEdit,
    closeEnv: [],
  },
  {
    path: `${prefix}/homeroom/attitude-note/`,
    exact: true,
    component: HomeroomAttitudeNote,
  },
  {
    path: `${prefix}/homeroom/attitude-note/:hashStudentId`,
    exact: true,
    component: HomeroomAttitudeNoteStudent,
  },
  {
    path: `${prefix}/homeroom/attitude-note/:hashNoteId/detail`,
    exact: true,
    component: HomeroomAttitudeNoteStudentDetail,
  },
  {
    path: `${prefix}/homeroom/attitude-note/:hashNoteId/edit`,
    exact: true,
    component: HomeroomAttitudeNoteStudentEdit,
  },
  {
    path: `${prefix}/complete-data`,
    component: CompleteDataRoutes,
    pageTitle: 'Lengkapi Data',
  },
  {
    path: `${prefix}/mbg`,
    component: MBGRoutes,
    pageTitle: 'Menu MBG',
  },
];

export default teacherRoutes;
