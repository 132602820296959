import React from 'react';

const LinkAjaError = React.lazy(() => import('../views/linkaja/error/linkaja-error.page'));
const LinkAjaConnect = React.lazy(() => import('../views/linkaja/linkaja-connect.page'));
const LinkAjaIntegrationSuccess = React.lazy(() =>
  import('../views/linkaja/success_integration/linkaja-integration-success.page'),
);
const LinkAjaTransactionSuccess = React.lazy(() =>
  import('../views/linkaja/success_transaction/linkaja-transaction-success.page'),
);
const LinkAjaEndpointIntegration = React.lazy(() =>
  import('../views/linkaja/endpoint_integration/linkaja-endpoint-integration.page'),
);
const LinkAjaEndpointTransaction = React.lazy(() =>
  import('../views/linkaja/endpoint_transaction/linkaja-endpoint-transaction.page'),
);

const prefix = 'link-aja';
const linkAjaRoutes = [
  {
    path: `/${prefix}/error`,
    component: LinkAjaError,
    pageTitle: 'Link Aja error',
    closeEnv: ['production'],
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/${prefix}/connect`,
    component: LinkAjaConnect,
    pageTitle: 'Link Aja Connect',
    closeEnv: ['production'],
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/${prefix}/transaction/:token`,
    component: LinkAjaEndpointTransaction,
    pageTitle: 'Link Aja Transaction',
    closeEnv: ['production'],
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/${prefix}/integration/:token`,
    component: LinkAjaEndpointIntegration,
    pageTitle: 'Link Aja Integration',
    closeEnv: ['production'],
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/${prefix}/success_integration`,
    component: LinkAjaIntegrationSuccess,
    pageTitle: 'Link Aja Success Integration',
    closeEnv: ['production'],
    allowedRole: ['student', 'parent', 'teacher'],
  },
  {
    path: `/${prefix}/success_transaction`,
    component: LinkAjaTransactionSuccess,
    pageTitle: 'Link Aja Success Transaction',
    closeEnv: ['production'],
    allowedRole: ['student', 'parent', 'teacher'],
  },
];
export default linkAjaRoutes;
