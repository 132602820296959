import React from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as SkulSvg } from 'assets/logos/logo.svg';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';

const Wrapper = styled.div`
  > svg,
  a > svg {
    width: 80px;
    cursor: pointer;
  }

  @media ${device.laptop} {
    > svg,
    a > svg {
      width: 110px;
    }
  }
`;

export default function Logo() {
  const path = window.location.pathname;
  const history = useHistory();
  const onClickHandler = () => history.push('/');

  if (path === '/') {
    return (
      <Wrapper>
        <a href="https://skul.id">
          <SkulSvg title="Skul.id" />
        </a>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <SkulSvg onClick={onClickHandler} title="Skul.id" />
    </Wrapper>
  );
}
