export const USER_ROLE = {
  student: {
    id: process.env.REACT_APP_STUDENT_ID ? parseInt(process.env.REACT_APP_STUDENT_ID) : null,
    key: process.env.REACT_APP_STUDENT_SECRET,
    value: 'student',
    valueIndo: 'siswa',
    apiUrlPrefix: 'user',
    appUrlPrefix: '/',
  },
  parent: {
    id: process.env.REACT_APP_PARENT_ID ? parseInt(process.env.REACT_APP_PARENT_ID) : null,
    key: process.env.REACT_APP_PARENT_SECRET,
    value: 'parent',
    valueIndo: 'orang tua',
    apiUrlPrefix: 'parent',
    appUrlPrefix: '/parent/',
  },
  teacher: {
    id: process.env.REACT_APP_TEACHER_ID ? parseInt(process.env.REACT_APP_TEACHER_ID) : null,
    key: process.env.REACT_APP_TEACHER_SECRET,
    value: 'teacher',
    valueIndo: 'guru',
    apiUrlPrefix: 'teacher',
    appUrlPrefix: '/teacher/',
  },
  school: {
    id: process.env.REACT_APP_SCHOOL_ID ? parseInt(process.env.REACT_APP_SCHOOL_ID) : null,
    key: process.env.REACT_APP_SCHOOL_SECRET,
    value: 'school',
    valueIndo: 'sekolah',
    apiUrlPrefix: 'school',
    appUrlPrefix: '/school/',
  },
};

/**
 * Used to get role string from role id
 * @param {number | string} roleId
 * @returns string | null
 */
export const switchRole = roleId => {
  if (!roleId) return null;

  switch (parseInt(roleId)) {
    case USER_ROLE.student.id:
      return USER_ROLE.student.value;
    case USER_ROLE.parent.id:
      return USER_ROLE.parent.value;
    case USER_ROLE.teacher.id:
      return USER_ROLE.teacher.value;
    case USER_ROLE.school.id:
      return USER_ROLE.school.value;
    default:
      return null;
  }
};

/**
 * Used to get role id from role string
 * @param {string} role
 * @returns number | null
 */
export const switchRoleId = role => {
  if (!role) return null;

  switch (role) {
    case USER_ROLE.student.value:
      return USER_ROLE.student.id;
    case USER_ROLE.parent.value:
      return USER_ROLE.parent.id;
    case USER_ROLE.teacher.value:
      return USER_ROLE.teacher.id;
    case USER_ROLE.school.value:
      return USER_ROLE.school.id;
    default:
      return null;
  }
};

/**
 * Used to get role api url prefix
 * @param {number|string} role
 * @returns string | null
 */
export const switchRoleUrl = role => {
  if (!role) return null;

  if (role.length === 1) {
    switch (parseInt(role)) {
      case USER_ROLE.student.id:
        return USER_ROLE.student.apiUrlPrefix;
      case USER_ROLE.parent.id:
        return USER_ROLE.parent.apiUrlPrefix;
      case USER_ROLE.teacher.id:
        return USER_ROLE.teacher.apiUrlPrefix;
      case USER_ROLE.school.id:
        return USER_ROLE.school.apiUrlPrefix;
      default:
        return null;
    }
  } else {
    switch (role) {
      case USER_ROLE.student.value:
        return USER_ROLE.student.apiUrlPrefix;
      case USER_ROLE.parent.value:
        return USER_ROLE.parent.apiUrlPrefix;
      case USER_ROLE.teacher.value:
        return USER_ROLE.teacher.apiUrlPrefix;
      case USER_ROLE.school.value:
        return USER_ROLE.school.apiUrlPrefix;
      default:
        return null;
    }
  }
};

/**
 *
 * @param {string} role
 * @returns string | null
 */
export const switchRoleIndo = role => {
  if (!role) return null;

  switch (role) {
    case USER_ROLE.student.value:
      return USER_ROLE.student.valueIndo;
    case USER_ROLE.parent.value:
      return USER_ROLE.parent.valueIndo;
    case USER_ROLE.teacher.value:
      return USER_ROLE.teacher.valueIndo;
    case USER_ROLE.school.value:
      return USER_ROLE.school.valueIndo;
    default:
      return null;
  }
};
