import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../utils/breakpoints';
import { ReactComponent as IOSSVG } from 'assets/images/btn_appstore.svg';
import { ReactComponent as AndroidSVG } from 'assets/images/btn_googleplay.svg';
import { ReactComponent as HuaweiSVG } from 'assets/images/btn_huawei.svg';
import { getUserDeviceInfo } from 'helpers/user/user-device.helper';

const Wrapper = styled.div`
  border: 1px solid #d7e1e7;
  border-radius: 10px;
  padding: 16px;
  margin: 0 auto;

  > p {
    font-size: 13px;
    margin-bottom: 16px;
  }

  @media ${device.tabletM} {
    display: grid;
    grid-template-columns: 45% 50%;
    gap: 32px;
    > p {
      margin-bottom: 0;
    }
  }

  @media ${device.laptop} {
    padding: 24px;
    grid-template-columns: 57% 40%;
    word-wrap: normal;
    > p {
      font-size: 16px;
    }
  }
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;

  > button {
    padding: 0;
    background: none;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      > svg {
        width: 100%;
      }
    }
  }
`;

const DownloadApp = () => {
  const {
    os: { name },
  } = getUserDeviceInfo();

  const onClickHandler = osParam => {
    if (osParam === 'android') {
      if (name === 'Android') {
        window.open('market://details?id=co.id.telkomsel', '_target');
      } else {
        window.open('https://play.google.com/store/apps/details?id=co.id.telkomsel', '_target');
      }
    }

    if (osParam === 'ios') {
      window.open('https://apps.apple.com/id/app/skul-id-school-platform/id6444022306', '_blank');
    }

    if (osParam === 'huawei') {
      window.open('https://appgallery.huawei.com/app/C104929199', '_blank');
    }
  };

  return (
    <Wrapper>
      <p>
        Dapatkan aplikasi skul.id agar dapat melakukan presensi online, lihat jadwal mata pelajaran,
        dan fitur-fitur lainnya.
      </p>

      <ButtonGroup>
        <button type="button" onClick={() => onClickHandler('ios')}>
          <div>
            <IOSSVG />
          </div>
        </button>
        <button type="button" onClick={() => onClickHandler('android')} rel="nofollow">
          <div>
            <AndroidSVG />
          </div>
        </button>
        <button type="button" onClick={() => onClickHandler('huawei')}>
          <div>
            <HuaweiSVG />
          </div>
        </button>
      </ButtonGroup>
    </Wrapper>
  );
};

export default DownloadApp;
