import { LoadingPage } from 'components/Loading';
import { USER_ROLE } from 'helpers/switchRole';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { selectUserReducer } from 'store/user/user.selector';

const GuestRoute = ({ component: Component, pageTitle, ...rest }) => {
  const { isAuthenticated, isAuthLoading, currentUser, role } = useSelector(selectUserReducer);

  useEffect(() => {
    if (pageTitle) return (document.title = 'Skul.id | ' + pageTitle);
    document.title = 'Skul.id';
  }, [pageTitle]);

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthLoading) {
          return <LoadingPage />;
        }

        if (!isAuthLoading && !isAuthenticated) {
          return <Component {...props} />;
        }

        if (!isAuthLoading && isAuthenticated && !isEmpty(currentUser)) {
          return <Redirect to={{ pathname: `${USER_ROLE[role].appUrlPrefix}home` }} />;
        }
      }}
    />
  );
};

export default GuestRoute;
