export const selectUserReducer = state => state.user;
export const selectCurrentUser = state => state.user.currentUser;
export const selectCurrentUserRole = state => state.user.role;
export const selectStudents = state => state.user.currentUser?.students || [];

export const selectStudentsHaveSchool = state => {
  return state.user.currentUser?.students.reduce((accumulator, currentStudent) => {
    const isHasSchool = currentStudent?.school?.name;
    return isHasSchool ? [...accumulator, currentStudent] : accumulator;
  }, []);
};
