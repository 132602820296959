import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTokenFirebase,
  isNotifSupported,
  fetchRemoteConfig,
  getConfigValue,
} from './configs/firebase';
import { encodeHashNonPromise } from 'helpers/hashes';
import { setPreferencesFromLocalStorage } from 'store/preferences/preferences.action';
import { setHost } from 'store/get-config-firebase/host.action';

import { useState } from 'react';
import { selectPreferences } from 'store/preferences/preferences.selector';
import { useEffect } from 'react';
import { isEmpty, isNil } from 'lodash';
import { checkUserSessionAsync } from 'store/user/user.thunk';
import { updateFcmClient } from 'services/notification.services';
import Routes from 'routes/index.routes';
import ErrorBoundary from 'views/error/error-boundary.page';

const App = () => {
  const dispatch = useDispatch();
  const preferences = useSelector(selectPreferences);
  const { currentUser, isAuthenticated } = useSelector(state => state.user);
  const { id: currentUserId = null, is_sub_push_notif: isPushNotifChannelOn = false } = currentUser;
  const [clientToken, setClientToken] = useState('');

  useEffect(() => {
    dispatch(checkUserSessionAsync());
    const loadConfig = async () => {
      await fetchRemoteConfig();
      const { host } = JSON.parse(getConfigValue('config'));

      dispatch(setHost(host));
    };

    loadConfig();
  }, [dispatch]);

  /**
   * Why used session preferences?
   * If user click skip in connect socmed page without check on "Jangan tampilkan lagi", the connect socmed page will be appear again in another tabs
   * The value of socmedSkip in local storage will be override to true if socmedSkip is exist in session storage, if does not exist in session storage, the value will be set to false
   * The socmedSkip value will be ignore in render and the connect socmed page will not appear in any user devices if user check on "Jangan tampilkan lagi", why ? cause it will be saved in database
   */
  const checkSavedPreferences = useCallback(() => {
    if (!isAuthenticated || !currentUserId) return;

    try {
      const encodedUserId = encodeHashNonPromise(currentUserId);
      const storagePreferences = JSON.parse(window.localStorage.getItem(encodedUserId));
      let sessionPreferences = JSON.parse(window.sessionStorage.getItem(encodedUserId));

      if (!isNil(storagePreferences) && !isEmpty(storagePreferences)) {
        sessionPreferences = sessionPreferences ? sessionPreferences : { socmedSkip: false };
        dispatch(setPreferencesFromLocalStorage({ ...storagePreferences, ...sessionPreferences }));
      } else {
        window.localStorage.setItem(encodedUserId, JSON.stringify(preferences));
      }
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, currentUserId, dispatch]);

  useEffect(() => {
    checkSavedPreferences();
  }, [checkSavedPreferences]);

  const pushNotificationHandler = useCallback(() => {
    if (!isAuthenticated || !currentUserId) {
      setClientToken('');
      return;
    }

    if (isNotifSupported && isPushNotifChannelOn && clientToken === '') {
      if ('Notification' in window && Notification.permission !== 'denied') {
        Notification.requestPermission().then(async permission => {
          if (permission === 'granted') {
            try {
              const dataClientToken = await getTokenFirebase();

              await updateFcmClient(dataClientToken);
              setClientToken(dataClientToken);
            } catch (e) {}
          }
        });
      }
    }
  }, [isAuthenticated, currentUserId, isPushNotifChannelOn]);

  useEffect(() => {
    pushNotificationHandler();
  }, [pushNotificationHandler]);

  return (
    <ErrorBoundary>
      <Routes />
    </ErrorBoundary>
  );
};

export default App;
