import { lazy } from 'react';
const EditSocialMedia = lazy(() => import('views/_parent/profileEdit/EditSocialMedia'));
const AttitudeNote = lazy(() => import('../views/_parent/attitudeNote/AttitudeNote'));
const NoteDetail = lazy(() => import('../views/_parent/attitudeNote/NoteDetail'));
const Beranda = lazy(() => import('../views/beranda/Beranda'));
const EditPermit = lazy(() => import('../views/_parent/permit-v2/edit-permit.page'));
const CreatePermit = lazy(() => import('views/_parent/permit-v2/create-permit.page'));
const ScheduleParent = lazy(() => import('../views/schedule/Schedule'));
const HistoryWaitingList = lazy(() => import('../views/history/HistoryWaitingList'));
const GradeRoutes = lazy(() => import('../views/_parent/grade/GradeRoutes'));
const ParentAttendanceSummaryDetails = lazy(() =>
  import('../views/history/ParentAttendanceSummaryDetails'),
);
const HistoryParent = lazy(() => import('../views/history/HistoryParent'));
const HistoryPermitDetail = lazy(() =>
  import('../views/_parent/history-permit/history-permit-detail.page'),
);
const HistoryAttendanceDetail = lazy(() => import('../views/history/HistoryAttendanceDetail'));
const EmailEdit = lazy(() => import('../views/email-edit/email-edit.page'));
const EditProfile = lazy(() => import('../views/_parent/profileEdit/EditProfile'));
const FormStudent = lazy(() => import('../views/_parent/connectStudent/pages/FormStudent'));
const ListStudent = lazy(() => import('../views/_parent/connectStudent/pages/ListStudent'));
const ConnectStudent = lazy(() => import('../views/_parent/connectStudent/pages/ConnectStudent'));
const Profile = lazy(() => import('../views/_parent/profile/profile-parent.page'));
const Notification = lazy(() => import('../views/notification/notification-parent.page'));
const Point = lazy(() => import('../views/_parent/point/Point'));
const UnderConstruction = lazy(() => import('../views/error/under-construction.page'));
const Eraport = lazy(() => import('../views/_parent/eraport/Eraport'));
const EraportDetail = lazy(() => import('../views/_parent/eraport/EraportDetail'));
const PhoneEdit = lazy(() => import('views/phone-edit/phone-edit.page'));
const OTPRequest = lazy(() => import('views/phone-edit/OTP-request.page'));
const OTPVerify = lazy(() => import('views/phone-edit/OTP-verify.page'));
const CompleteDataRoutes = lazy(() => import('views/complete-data/complete-data.routes'));
const MBGRoutes = lazy(() => import('views/mbg/mbg.routes'));

const prefix = '/parent';
const parentRoutes = [
  { path: `${prefix}/home`, exact: true, component: Beranda, pageTitle: 'Beranda' },
  { path: `${prefix}/koin`, exact: true, component: Point, pageTitle: 'Koin' },
  {
    path: `${prefix}/koin/gift`,
    exact: true,
    component: UnderConstruction,
    pageTitle: 'Hadiah Koin',
  },
  {
    path: `${prefix}/notifications`,
    exact: true,
    component: Notification,
    pageTitle: 'Notifikasi',
  },
  {
    path: `${prefix}/e-raport`,
    exact: true,
    component: Eraport,
    pageTitle: 'E-Rapor',
  },
  {
    path: `${prefix}/e-raport/:uuid`,
    exact: true,
    component: EraportDetail,
    pageTitle: 'E-Rapor detail',
  },
  { path: `${prefix}/profile`, exact: true, component: Profile, pageTitle: 'Profile Saya' },
  { path: `${prefix}/profile/edit`, exact: true, component: EditProfile, pageTitle: 'Edit Profil' },
  {
    path: `${prefix}/profile/edit/email`,
    exact: true,
    component: EmailEdit,
    pageTitle: 'Edit email',
  },
  {
    path: `${prefix}/profile/edit/phone`,
    exact: true,
    component: PhoneEdit,
    pageTitle: 'Edit phone',
  },
  {
    path: `${prefix}/profile/edit/phone/request-otp`,
    exact: true,
    component: OTPRequest,
  },
  {
    path: `${prefix}/profile/edit/phone/verify-otp`,
    exact: true,
    component: OTPVerify,
  },
  {
    path: `${prefix}/profile/edit/social-media`,
    exact: true,
    component: EditSocialMedia,
    pageTitle: 'Tambah akun social media',
  },
  { path: `${prefix}/profile/list/student`, exact: true, component: ListStudent },
  { path: `${prefix}/profile/add/student`, exact: true, component: FormStudent },
  { path: `${prefix}/profile/connect/student`, exact: true, component: ConnectStudent },

  { path: `${prefix}/history`, exact: true, component: HistoryParent },
  {
    path: `${prefix}/history/summary/`,
    exact: true,
    component: ParentAttendanceSummaryDetails,
  },
  { path: `${prefix}/history/attendance/:slug`, exact: true, component: HistoryAttendanceDetail },
  { path: `${prefix}/history/permit/:permitHashId`, exact: true, component: HistoryPermitDetail },
  { path: `${prefix}/waiting/attendance/:slug`, exact: true, component: HistoryWaitingList },
  { path: `${prefix}/grade`, exact: false, component: GradeRoutes },
  { path: `${prefix}/schedule`, exact: true, component: ScheduleParent },
  { path: `${prefix}/permit`, exact: true, component: CreatePermit },
  { path: `${prefix}/permit/edit/:permitHashId`, exact: true, component: EditPermit },
  {
    path: `${prefix}/attitude-note`,
    exact: true,
    component: AttitudeNote,
    pageTitle: 'Catatan Sikap Siswa',
  },
  {
    path: `${prefix}/attitude-note/:hashNoteId`,
    exact: true,
    component: NoteDetail,
    pageTitle: 'Catatan Sikap Siswa',
  },
  {
    path: `${prefix}/complete-data`,
    component: CompleteDataRoutes,
    pageTitle: 'Lengkapi Data',
  },
  {
    path: `${prefix}/mbg`,
    component: MBGRoutes,
    pageTitle: 'Menu MBG',
  },
];
export default parentRoutes;
