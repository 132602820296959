import { axiosPrivate, axiosPublic } from 'configs/axios-instance.configs';
import { ERROR_CONSTANT } from 'constant/error-code.constant';
import { USER_ROLE } from 'helpers/switchRole';
import QueryString from 'qs';
import { getAccessRoleIdStatus, getAccessTokenStatus, TOKEN_ERROR } from 'utils/checkTokenExpire';
import { getError } from 'utils/service/service.utils';
import { refreshAuthToken } from './auth.services';
import { getUserProperty } from './contracts/user.contract';

const userService = {
  async checkUserSession() {
    const { accessRoleApiUrlPrefix: prefix, accessToken } = getUserProperty();

    try {
      if (getAccessRoleIdStatus() === TOKEN_ERROR.INVALID_SAVED_ROLE) {
        throw Error(TOKEN_ERROR.INVALID_SAVED_ROLE);
      }

      const ACStatus = getAccessTokenStatus();
      if (
        ACStatus === TOKEN_ERROR.INVALID_ACCESS_TOKEN ||
        ACStatus === TOKEN_ERROR.EMPTY_ACCESS_TOKEN
      ) {
        throw Error(ACStatus);
      }

      if (ACStatus === TOKEN_ERROR.EXPIRED_ACCESS_TOKEN) {
        const newAccessToken = await refreshAuthToken();
        const { data } = await axiosPublic.get(`/v1/${prefix}/me/profile`, {
          headers: {
            Authorization: `Bearer ${newAccessToken}`,
          },
        });
        return Promise.resolve({ user: data.data, userRole: data.data.user_type });
      } else {
        const { data } = await axiosPublic.get(`/v1/${prefix}/me/profile`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        return Promise.resolve({ user: data.data, userRole: data.data.user_type });
      }
    } catch (error) {
      // reject here will cause by invalid refresh token or access token, so we don't need to call logout
      return Promise.reject(getError(error));
    }
  },
  /**
   * Used to update single data
   * @param {object} param
   * @param {'phone' | 'email'} param.field
   * @param {string | number} param.value
   * @param {string} param.role student | teacher | parent
   * @param {string=} param.secret_token
   * @returns Promise
   */
  async updateSingleField({ field, value, role, secret_token }) {
    try {
      const prefix = USER_ROLE[role].apiUrlPrefix;
      const url = `v1/${prefix}/me/profile/update/patch`;

      const response = await axiosPrivate.put(
        url,
        QueryString.stringify({ field, value, secret_token }),
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        },
      );

      return Promise.resolve({ user: response.data.data, userRole: role });
    } catch (error) {
      let { status, message, code } = getError(error);

      if (status === 422) {
        return Promise.reject({ status, message: message[0], code });
      }

      if (code === ERROR_CONSTANT.EDIT_PHONE.INVALID_TOKEN) {
        return Promise.reject({
          title: message,
          message: 'Silahkan ulangi proses ubah no. handphone',
          status,
          code,
        });
      }

      return Promise.reject({ status, message, code });
    }
  },

  /**
   * Used to verify user data
   * @param {string} role student | teacher | parent
   * @param {string} field
   * @param {string} value
   * @param {boolean} isNotUseBearerToken
   * @returns Promise
   */
  async verifySingleData({ role, field, value, isNotUseBearerToken }) {
    try {
      let response = null;

      if (isNotUseBearerToken) {
        response = await axiosPublic.get('v1/validate', {
          params: { stakeholder: role, field, value },
        });
      } else {
        response = await axiosPrivate.get('v1/validate', {
          params: { stakeholder: role, field, value },
        });
      }

      return Promise.resolve(response);
    } catch (error) {
      const { status, code, message } = getError(error);
      if (status === 422 || code === 422) {
        return Promise.reject({ status, code, message: `${field} tidak tersedia` });
      }

      return Promise.reject({ status, code, message });
    }
  },

  /**
   * Used to get user interest
   * @param {object} param
   * @param {string} param.name
   * @param {number} param.page default 10
   * @param {number} param.per_page default 1
   * @param {boolean} param.isNotUseBearerToken
   * @returns Promise
   */
  async getInterest({ name = null, per_page = null, page = null, isNotUseBearerToken }) {
    try {
      let response = null;

      if (isNotUseBearerToken) {
        response = await axiosPublic.get('v1/interest_field_users', {
          params: { name, per_page, page },
        });
      } else {
        response = await axiosPrivate.get('v1/interest_field_users', {
          params: { name, per_page, page },
        });
      }

      return Promise.resolve(response);
    } catch (error) {
      const { status, code, message } = getError(error);
      return Promise.reject({ status, code, message });
    }
  },

  /**
   *
   * @param {object} param
   * @param {number[]} param.interest_fields
   * @param {string} param.role
   * @returns Promise
   */
  async updateInterests({ interest_fields, role }) {
    try {
      const prefix = USER_ROLE[role].apiUrlPrefix;
      const url = `v1/${prefix}/me/profile/interest_fields`;

      const { data } = await axiosPrivate.put(
        url,
        QueryString.stringify({
          interest_fields: interest_fields.join(','),
        }),
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        },
      );

      return Promise.resolve({ user: data.data, userRole: role });
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  /**
   * Used to get user profile
   * @returns Promise
   */
  async getProfile() {
    try {
      const { accessRoleApiUrlPrefix: prefix } = getUserProperty();

      const { data } = await axiosPrivate.get(`/v1/${prefix}/me/profile`);

      return Promise.resolve({ user: data.data, userRole: data.data.user_type });
    } catch (error) {
      const { status, code, message } = getError(error);
      return Promise.reject({ status, code, message });
    }
  },

  async getPhoneEncrypted() {
    try {
      const url = `v1/phone-enc`;
      const data = await axiosPrivate.get(url);
      return Promise.resolve({ encrypted: data.data.encrypted });
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  /**
   * Used to get address by lat lng
   * @param {object} param
   * @param {number} param.latitude
   * @param {number} param.longitude
   * @param {string} param.language
   * @returns Promise
   */
  async getAddressByGeocode({ latitude, longitude, language = 'id' }) {
    try {
      const { data } = await axiosPrivate.get(`/v1/geocode`, {
        params: { latitude, longitude, language },
      });

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },
};
/**
 * Used to reset user password
 * @param {string} phone
 * @param {string} password
 * @param {string} password_confirmation
 * @param {string} role student | teacher | parent
 * @param {string} secret_token secret_token got after successfully verified otp
 * @returns Promise
 */
export const resetPassword = async ({
  phone,
  password,
  password_confirmation,
  role,
  secret_token,
}) => {
  try {
    // exclusive for this api, be changed student prefix from user to student
    const response = await axiosPublic.put(
      `v1/${role}/16-03-2023/password/reset`,
      QueryString.stringify({ phone, password, password_confirmation, secret_token }),
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      },
    );
    return Promise.resolve(response);
  } catch (error) {
    const { message, status, code } = getError(error);

    if (code === ERROR_CONSTANT.RESET_PASSWORD.INVALID_TOKEN) {
      return Promise.reject({
        title: message,
        message: 'Silahkan ulangi proses reset password',
        status,
        code,
      });
    }

    return Promise.reject({ message, status, code });
  }
};

/**
 * @param {number} class_id
 * @returns Promise
 */
export const updateClassStudent = async class_id => {
  try {
    const { data } = await axiosPrivate.put(
      `/v1/user/class_student`,
      QueryString.stringify({ class_id }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

    return Promise.resolve({ user: data.data, userRole: data.data.user_type });
  } catch (error) {
    return Promise.reject(getError(error));
  }
};

export const educationLevel = async () => {
  try {
    const { data } = await axiosPublic.get(`/v1/educational-level`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(getError(error));
  }
};

export default userService;
