import { axiosPrivate } from 'configs/axios-instance.configs';
import QueryString from 'qs';
import { getError } from 'utils/service/service.utils';
import { getUserProperty } from './contracts/user.contract';

/**
 *
 * @param {boolean} signal - Abortcontroller signal
 * @returns {promise}
 */
export const allNotification = async signal => {
  try {
    const { accessRoleApiUrlPrefix: prefix } = getUserProperty();

    const responseUnread = await axiosPrivate.get(`/v1/${prefix}/notifications?type=unread`, {
      signal,
    });
    const responseRead = await axiosPrivate.get(`/v1/${prefix}/notifications?type=read`, {
      signal,
    });
    const responseAll = await axiosPrivate.get(`/v1/${prefix}/notifications?type=all`, {
      signal,
    });

    const data = {
      unread: responseUnread.data.data,
      read: responseRead.data.data,
      all: responseAll.data.data,
    };

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(getError(error));
  }
};

/**
 *
 * @param {number[]} theIds - array of notification id
 * @returns {promise}
 */
export const markRead = async theIds => {
  try {
    const { accessRoleApiUrlPrefix: prefix } = getUserProperty();

    const data = {
      ids: theIds.map(any => any).toString(),
    };

    const response = await axiosPrivate.put(
      `/v1/${prefix}/notifications/mark_read`,
      QueryString.stringify(data),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(getError(error));
  }
};

/**
 *
 * @param {string} token
 * @returns promise
 */
export const updateFcmClient = async token => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const { accessRoleApiUrlPrefix: prefix } = getUserProperty();

    const url = `v1/${prefix}/me/fcm_token`;
    const response = await axiosPrivate.put(
      url,
      QueryString.stringify({ fcm_token: token }),
      config,
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(getError(error));
  }
};
