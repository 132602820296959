import { lazy } from 'react';
const AttitudeNote = lazy(() => import('../views/_student/attitudeNote/AttitudeNote'));
const NoteDetail = lazy(() => import('../views/_student/attitudeNote/NoteDetail'));
// const TugasKu = lazy(() => import('../views/_student/tugasku/TugasKu'));
const Beranda = lazy(() => import('../views/beranda/Beranda'));
const Notification = lazy(() => import('../views/notification/notification-student.page'));
const ProfileStudent = lazy(() => import('../views/_student/profile/profile-student.page'));
const ProfileEditStudent = lazy(() => import('../views/_student/profile_edit/ProfileEditStudent'));
const EditSocialMedia = lazy(() => import('../views/_student/profile_edit/pages/EditSocialMedia'));
const EmailEdit = lazy(() => import('../views/email-edit/email-edit.page'));
const FavoriteEdit = lazy(() => import('../views/favorite-edit/favorite-edit.page'));
const ParentEdit = lazy(() => import('../views/_student/profile_edit/pages/ParentEdit'));
const ParentList = lazy(() => import('../views/_student/profile_edit/pages/ParentList'));
const ParentNotFound = lazy(() => import('../views/_student/profile_edit/pages/ParentNotFound'));
const ParentFound = lazy(() => import('../views/_student/profile_edit/pages/ParentFound'));
const SchoolList = lazy(() => import('../views/_student/profile_edit/pages/SchoolList'));
const EmptySchool = lazy(() => import('../views/empty-school/empty-school.page'));
const SearchClass = lazy(() => import('../views/_student/connect-school/search-class.page.jsx'));
const SearchSchool = lazy(() => import('../views/search-school/search-school.page'));
const ConnectSchool = lazy(() => import('../views/_student/connect-school/connect-school.page'));
const CreatePermit = lazy(() => import('views/_student/permit-v2/create-permit.page'));
const EditPermit = lazy(() => import('views/_student/permit-v2/edit-permit.page'));
const StudentAttendanceSummaryDetails = lazy(() =>
  import('../views/history/StudentAttendanceSummaryDetails'),
);
const HistoryStudent = lazy(() => import('../views/history/HistoryStudent'));
const HistoryAttendanceDetail = lazy(() => import('../views/history/HistoryAttendanceDetail'));
const HistoryPermitDetail = lazy(() =>
  import('views/_student/history-permit/history-permit-detail.page'),
);
const GradeRoutes = lazy(() => import('../views/_student/grade/GradeRoutes'));
const Schedule = lazy(() => import('../views/schedule/Schedule'));
const PointV2 = lazy(() => import('../views/coin/Point-v2'));
const Reward = lazy(() => import('../views/reward-koin/RewardList'));
const ClaimList = lazy(() => import('../views/reward-koin/RewardClaimList'));
const RewardDetail = lazy(() => import('../views/reward-koin/DetailReward'));

// tugasku
const TugasKu = lazy(() => import('../views/_student/tugasku/TugasKu'));
const TugasKuJoin = lazy(() => import('../views/_student/tugasku/TugasKuJoin'));
const TugasKuDetail = lazy(() => import('../views/_student/tugasku/TugasKuDetail'));
const TugasKuInfoHistory = lazy(() => import('../views/_student/tugasku/TugasKuInfoHistory'));
const TugasKuInfoClass = lazy(() => import('../views/_student/tugasku/TugasKuInfoClass'));
const TugasKuTasksDetail = lazy(() => import('../views/_student/tugasku/TugasKuTasksDetail'));
const TugasKuQuizzesDetail = lazy(() => import('../views/_student/tugasku/TugasKuQuizzesDetail'));
const TugasKuModulesDetail = lazy(() => import('../views/_student/tugasku/TugasKuModulesDetail'));
const TugasKuAnnouncesDetail = lazy(() =>
  import('../views/_student/tugasku/TugasKuAnnouncesDetail'),
);
const TugasKuTasksDetailFile = lazy(() =>
  import('../views/_student/tugasku/TugasKuTasksDetailFile'),
);
const TugasKuModulesDetailFile = lazy(() =>
  import('../views/_student/tugasku/TugasKuModulesDetailFile'),
);
const UnderConstruction = lazy(() => import('../views/error/under-construction.page'));
const Eraport = lazy(() => import('../views/_student/eraport/Eraport'));
const EraportDetail = lazy(() => import('../views/_student/eraport/EraportDetail'));

const PhoneEdit = lazy(() => import('views/phone-edit/phone-edit.page'));
const OTPRequest = lazy(() => import('views/phone-edit/OTP-request.page'));
const OTPVerify = lazy(() => import('views/phone-edit/OTP-verify.page'));
const CompleteDataRoutes = lazy(() => import('views/complete-data/complete-data.routes'));

const studentRoutes = [
  {
    path: '/home',
    exact: true,
    component: Beranda,
    pageTitle: 'Beranda',
  },
  {
    path: '/notifications',
    exact: true,
    component: Notification,
    pageTitle: 'Beranda',
  },
  {
    path: '/profile',
    exact: true,
    component: ProfileStudent,
    pageTitle: 'Profil saya',
  },
  {
    path: '/profile/edit',
    exact: true,
    component: ProfileEditStudent,
    pageTitle: 'Ubah profil saya',
  },
  {
    path: '/profile/edit/phone',
    exact: true,
    component: PhoneEdit,
    pageTitle: 'Ubah nomor ponsel saya',
  },
  {
    path: `/profile/edit/phone/request-otp`,
    exact: true,
    component: OTPRequest,
  },
  {
    path: `/profile/edit/phone/verify-otp`,
    exact: true,
    component: OTPVerify,
  },
  {
    path: '/profile/edit/email',
    exact: true,
    component: EmailEdit,
    pageTitle: 'Ubah email saya',
  },
  {
    path: '/profile/edit/favorite',
    exact: true,
    component: FavoriteEdit,
    pageTitle: 'Ubah favorit saya',
  },
  {
    path: '/profile/edit/social-media',
    exact: true,
    component: EditSocialMedia,
    pageTitle: 'Tambah akun social media',
  },
  {
    path: '/profile/edit/parent',
    exact: true,
    component: ParentEdit,
    pageTitle: 'Ubah orang tua',
  },
  {
    path: '/profile/list/parent',
    exact: true,
    component: ParentList,
    pageTitle: 'Orang tua saya',
  },
  {
    path: '/profile/empty/parent/',
    exact: true,
    component: ParentNotFound,
  },
  {
    path: '/profile/found/parent/',
    exact: true,
    component: ParentFound,
  },
  {
    path: '/profile/my-school',
    exact: true,
    component: SchoolList,
  },
  {
    path: '/profile/empty-school',
    exact: true,
    component: EmptySchool,
  },
  {
    path: '/profile/search-school',
    exact: true,
    component: SearchSchool,
    pageTitle: 'Cari sekolah',
  },
  {
    path: '/profile/search-class',
    exact: true,
    component: SearchClass,
    pageTitle: 'Cari kelas',
  },
  {
    path: '/profile/connect-school/:newSchoolUuid',
    exact: true,
    component: ConnectSchool,
    pageTitle: 'Hubungkan sekolah',
  },
  {
    path: '/permit',
    exact: true,
    component: CreatePermit,
  },
  {
    path: '/permit/edit/:permitHashId',
    exact: true,
    component: EditPermit,
  },
  {
    path: '/history',
    exact: true,
    component: HistoryStudent,
  },
  {
    path: '/history/summary',
    exact: true,
    component: StudentAttendanceSummaryDetails,
  },
  {
    path: '/history/attendance/:slug',
    exact: true,
    component: HistoryAttendanceDetail,
  },
  {
    path: '/history/permit/:permitHashId',
    exact: true,
    component: HistoryPermitDetail,
  },
  {
    path: '/grade',
    exact: false,
    component: GradeRoutes,
  },
  {
    path: '/schedule',
    exact: true,
    component: Schedule,
  },
  {
    path: '/koin',
    exact: true,
    component: PointV2,
    pageTitle: 'Koin',
  },
  {
    path: '/koin/gift',
    exact: true,
    component: Reward,
    pageTitle: 'Hadiah Koin',
  },
  {
    path: '/koin/gift/:uuid',
    exact: true,
    component: RewardDetail,
    pageTitle: 'Hadiah Detail',
  },
  {
    path: `/claim/list`,
    exact: true,
    component: ClaimList,
    pageTitle: 'Klaim  Hadiah',
  },
  {
    path: '/e-raport',
    exact: true,
    component: Eraport,
    pageTitle: 'E-Rapor',
  },
  {
    path: '/e-raport/:uuid',
    exact: true,
    component: EraportDetail,
    pageTitle: 'E-Rapor detail',
  },
  {
    path: '/tugasku',
    exact: true,
    component: TugasKu,
    pageTitle: 'Tugasku',
  },
  {
    path: `/tugasku/join`,
    exact: true,
    component: TugasKuJoin,
    pageTitle: 'Tugasku Join New Room',
  },
  {
    path: `/tugasku/join/:code`,
    exact: true,
    component: TugasKuJoin,
    pageTitle: 'Tugasku Join New Room',
  },
  {
    path: `/tugasku/:hash`,
    exact: true,
    component: TugasKuDetail,
    pageTitle: 'Tugasku Detail',
  },
  {
    path: `/tugasku/:hash/notif`,
    exact: true,
    component: TugasKuInfoHistory,
    pageTitle: 'Tugasku Information History',
  },
  {
    path: `/tugasku/:hash/info`,
    exact: true,
    component: TugasKuInfoClass,
    pageTitle: 'Tugasku Info Kelas',
  },
  {
    path: `/tugasku/:hash/tasks/:hashtask`,
    exact: true,
    component: TugasKuTasksDetail,
    pageTitle: 'Tugasku Detail Tugas',
  },
  {
    path: `/tugasku/:hash/quizzes/:hashquiz`,
    exact: true,
    component: TugasKuQuizzesDetail,
    pageTitle: 'Tugasku Detail Quiz',
  },
  {
    path: `/tugasku/:hash/modules/:hashmodule`,
    exact: true,
    component: TugasKuModulesDetail,
    pageTitle: 'Tugasku Detail Materi',
  },
  {
    path: `/tugasku/:hash/announces/:hashannounce`,
    exact: true,
    component: TugasKuAnnouncesDetail,
    pageTitle: 'Tugasku Detail Pengumuman',
  },
  {
    path: `/tugasku/:hash/tasks/:hashtask/file/:hashmedia`,
    exact: true,
    component: TugasKuTasksDetailFile,
    pageTitle: 'Tugasku Task Detail File',
  },
  {
    path: `/tugasku/:hash/modules/:hashmodule/file/:hashmedia`,
    exact: true,
    component: TugasKuModulesDetailFile,
    pageTitle: 'Tugasku Module Detail File',
  },
  {
    path: `/attitude-note`,
    exact: true,
    component: AttitudeNote,
    pageTitle: 'Catatan Sikap Saya',
  },
  {
    path: `/attitude-note/:hashNoteId`,
    exact: true,
    component: NoteDetail,
    pageTitle: 'Catatan Sikap Saya',
  },
  {
    path: '/complete-data',
    component: CompleteDataRoutes,
    pageTitle: 'Lengkapi Data',
  },
];
export default studentRoutes;
