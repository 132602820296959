import { clearCookies, setAccessRole, setAccessToken, setRefreshToken } from 'configs/cookies';
import { switchRoleId, USER_ROLE } from 'helpers/switchRole';
import { getError } from 'utils/service/service.utils';
import { getUserProperty } from './contracts/user.contract';
import { axiosPrivate, axiosPublic } from 'configs/axios-instance.configs';
import { ERROR_CONSTANT } from 'constant/error-code.constant';

export const refreshAuthToken = async () => {
  try {
    const { refreshToken, accessRole } = getUserProperty();
    const { id: client_id, key: client_secret } = USER_ROLE[accessRole];

    const formData = new FormData();
    formData.append('grant_type', 'refresh_token');
    formData.append('refresh_token', refreshToken);
    formData.append('client_id', client_id);
    formData.append('client_secret', client_secret);

    const { data } = await axiosPublic.post('v1/oauth/token', formData);
    const { access_token, _expires_in, refresh_token, _token_type } = data;

    setAccessToken(access_token);
    setRefreshToken(refresh_token);

    return Promise.resolve(access_token);
  } catch (error) {
    return Promise.reject(error);
  }
};

const AuthService = {
  /**
   *
   * @param {number} client_id
   * @param {string} client_secret
   * @param {string} username
   * @param {string} password
   * @returns
   */
  async login({ client_id, client_secret, username, password }) {
    try {
      const formData = new FormData();
      formData.append('grant_type', 'password');
      formData.append('client_id', client_id);
      formData.append('client_secret', client_secret);
      formData.append('username', username);
      formData.append('password', password);

      const { data } = await axiosPublic.post('v1/oauth/token', formData);
      const { access_token, _expires_in, _popup_referral, refresh_token, _token_type, user } = data;

      setAccessToken(access_token);
      setRefreshToken(refresh_token);
      setAccessRole(switchRoleId(user.user_type));

      return Promise.resolve({ user, userRole: user.user_type });
    } catch (error) {
      const { message, code, status } = getError(error);

      if (status === 404) return Promise.reject({ message: 'Akun tidak ditemukan!', status, code });
      if (status === 400) {
        return Promise.reject({
          message: 'Username, nomor ponsel atau password tidak benar!',
          status,
          code,
        });
      }
      return Promise.reject({ message, code, status });
    }
  },

  /**
   * @param {object} p
   * @param {string} p.name user name
   * @param {string} p.dob Date of Birth Ex 1996-12-02
   * @param {string} p.username
   * @param {string} p.email
   * @param {string} p.password
   * @param {string} p.password_confirmation
   * @param {string} p.phone
   * @param {string=} p.instagram_username
   * @param {string=} p.tiktok_username
   * @param {string | number=} p.user_id student id, to connect on parent register
   * @param {array=} p.interest_fields
   * @param {string} p.role student | teacher | parent
   * @param {string} p.secret_token
   * @returns
   */
  async register({
    name,
    dob,
    username,
    email,
    password,
    password_confirmation,
    phone,
    instagram_username,
    tiktok_username,
    user_id,
    interest_fields,
    role,
    secret_token,
    province,
    regency,
    district,
    edu_level,
  }) {
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('dob', dob);
      formData.append('username', username);
      formData.append('email', email);
      formData.append('password', password);
      formData.append('password_confirmation', password_confirmation);
      formData.append('phone', phone);
      formData.append('secret_token', secret_token);
      formData.append('province_id', province);
      formData.append('regency_id', regency);
      formData.append('district_id', district);
      edu_level && formData.append('educational_level', edu_level);
      instagram_username && formData.append('instagram_username', instagram_username);
      tiktok_username && formData.append('tiktok_username', tiktok_username);

      user_id && formData.append('user_id', user_id);
      interest_fields && formData.append('interest_fields', interest_fields);

      const prefix = USER_ROLE[role].apiUrlPrefix;
      const { data } = await axiosPublic.post(`v1/${prefix}/register`, formData);

      return Promise.resolve(data.data);
    } catch (error) {
      let { status, message, code } = getError(error);

      if (code === ERROR_CONSTANT.REGISTER.INVALID_TOKEN) {
        return Promise.reject({
          title: message,
          message: 'Silahkan ulangi proses daftar akun',
          status,
          code,
        });
      }

      return Promise.reject({ message, status, code });
    }
  },

  async logout() {
    try {
      const { accessToken, accessRoleApiUrlPrefix: prefix } = getUserProperty();
      const formData = new FormData();
      formData.append('token', accessToken);

      await axiosPublic.post(`v1/${prefix}/logout`, formData);
      clearCookies();
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  /**
   * @param {object} p
   * @param {string} p.name user name
   * @param {string} p.username
   * @param {string} p.email
   * @param {string} p.password
   * @param {string} p.password_confirmation
   * @param {string} p.phone
   * @param {string=} p.instagram_username
   * @param {string=} p.tiktok_username
   * @param {string} p.role student | teacher | parent
   * @param {string} p.secret_token
   */
  async complete({
    name,
    email,
    password,
    password_confirmation,
    phone,
    username,
    instagram_username,
    tiktok_username,
    role,
    secret_token,
  }) {
    try {
      const prefix = USER_ROLE[role].apiUrlPrefix;

      const response = await axiosPrivate.put(
        `/v1/${prefix}/register/complete`,
        JSON.stringify({
          name,
          email,
          password,
          password_confirmation,
          phone,
          username,
          instagram_username,
          tiktok_username,
          secret_token,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      return Promise.resolve(response);
    } catch (error) {
      let { status, message, code } = getError(error);

      if (code === ERROR_CONSTANT.COMPLETE_DATA.INVALID_TOKEN) {
        return Promise.reject({
          title: message,
          message: 'Silahkan ulangi proses login dari awal',
          status,
          code,
        });
      }

      return Promise.reject({ message, status, code });
    }
  },

  /**
   * User login by social media
   * @param {String} token graph accesstoken
   * @param {String} provider provider google/facebook
   * @param {String} user_type user type student/parent/teacher
   * @returns Promise
   */
  async loginWithSocialMedia({ token, provider, user_type }) {
    try {
      const { data } = await axiosPublic.post(
        '/v1/social/login',
        JSON.stringify({ token, provider, user_type }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      const { access_token, refresh_token, user } = data;

      setAccessToken(access_token);
      setRefreshToken(refresh_token);
      setAccessRole(switchRoleId(user.user_type));

      return Promise.resolve({ user, userRole: user.user_type });
    } catch (error) {
      const { message, code, status } = getError(error);
      return Promise.reject({ message, code, status });
    }
  },
};

export default AuthService;
