import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { LoadingPage } from 'components/Loading';
import { USER_ROLE } from 'helpers/switchRole';
import { selectUserReducer } from 'store/user/user.selector';
import { setCurrentUserAsync } from 'store/user/user.thunk';

const ParentRoute = ({ component: Component, pageTitle, ...rest }) => {
  const { isAuthenticated, isAuthLoading, currentUser, role } = useSelector(selectUserReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) dispatch(setCurrentUserAsync());
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (pageTitle) return (document.title = 'Skul.id | ' + pageTitle);
    document.title = 'Skul.id';
  }, [pageTitle]);

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthLoading) {
          return <LoadingPage />;
        }

        if (!isAuthLoading && isAuthenticated && !isEmpty(currentUser)) {
          if (role !== USER_ROLE['parent'].value) return <Redirect to={{ pathname: '/' }} />;

          // if is_need_complete_data true and is not in complete-data routes
          // used to avoid infinite loop
          if (currentUser.is_need_complete_data && !props.match.url.includes('complete-data')) {
            return <Redirect to={{ pathname: '/parent/complete-data/request-otp' }} />;
          }

          return <Component {...props} />;
        }

        if (!isAuthLoading && !isAuthenticated) {
          return <Redirect to={{ pathname: '/parent/login', state: { from: props.location } }} />;
        }
      }}
    />
  );
};

export default ParentRoute;
