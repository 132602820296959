import { axiosPrivate } from 'configs/axios-instance.configs';
import { getError } from 'utils/service/service.utils';
import { getUserProperty } from './contracts/user.contract';

const gradeServices = {
  /**
   * Index student
   * @param {String} class_id class id
   * @param {String} subject_id subject id
   * @param {String} format pdf, xlsx
   * @returns Promise
   */
  async printStudentGrade(class_id, subject_id, format) {
    try {
      const config = {
        params: { class_id, subject_id, format },
      };
      const url = `/v1/teacher/grade/print`;
      const response = await axiosPrivate.get(url, config);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  /**
   * Index student
   * @param {String} user_id class id
   * @param {Object} data object fill with subject_id, items array
   * @returns Promise
   */
  async updateStudent(user_id, data) {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: { user_id },
      };
      const url = `/v1/teacher/grade/student/${user_id}`;
      const response = await axiosPrivate.put(url, JSON.stringify(data), config);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },
  /**
   * Index student
   * @param {String} user_id class id
   * @param {String} subject_id subject id
   * @returns Promise
   */

  async indexStudent(user_id, subject_id) {
    try {
      const config = {
        params: {
          user_id,
          subject_id,
        },
      };
      const url = `/v1/teacher/grade/student/${user_id}`;
      const response = await axiosPrivate.get(url, config);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  /**
   * Index class
   * @param {String} class_id class id
   * @param {String} subject_id subject id
   * @returns Promise
   */

  async indexClass(class_id, subject_id) {
    try {
      const config = {
        params: {
          class_id,
          subject_id,
        },
      };
      const url = `/v1/teacher/grade/class/${class_id}`;
      const response = await axiosPrivate.get(url, config);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  /**
   * Index subject
   * @param {String} class_id class id
   * @returns Promise
   */
  async indexSubject(class_id) {
    try {
      const config = {
        params: { class_id },
      };
      const url = `/v1/teacher/grade/subject`;
      const response = await axiosPrivate.get(url, config);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  /**
   * Index tugasku room
   * @param {String} class_id class id
   * @returns Promise
   */
  async indexTugaskuRoom(class_id) {
    try {
      const url = `/v1/teacher/grade/tugasku-room/${class_id}`;
      const response = await axiosPrivate.get(url);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  /**
   * Index class task
   * @param {String} class_id class id
   * @param {String} subject_id class id
   * @returns Promise
   */
  async indexClassTask(class_id, subject_id) {
    try {
      const config = {
        params: { class_id, subject_id },
      };
      const url = `/v1/teacher/grade/class-task`;
      const response = await axiosPrivate.get(url, config);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  /**
   * Get student index
   * @param {String} class_id class
   * @param {String} q query keyword
   * @param {String} per_page user per page limit
   * @param {String} page active page
   * @returns Promise
   */
  async getStudentIndex(class_id, q = null, per_page = 25, page = 1) {
    try {
      const config = {
        params: { q, per_page, page },
      };
      const url = `/v1/teacher/school/class/${class_id}/student`;
      const response = await axiosPrivate.get(url, config);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  /**
   * Index grade task
   * @param {string} class_id id of class
   * @param {string} subject_id id of subject
   * @param {string} title title task
   * @returns Promise
   */
  async indexGradeTask(class_id, subject_id, title) {
    try {
      const config = {
        params: { class_id, subject_id, title },
      };
      const url = `/v1/teacher/grade/class-task-grade`;
      const response = await axiosPrivate.get(url, config);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  /**
   * Store grade task
   * @param {Object} data object fill with class_id, subject_id, title, and grade array
   * @returns Promise
   */
  async storeGradeTask(data) {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const url = `/v1/teacher/grade/class-task-grade`;
      const response = await axiosPrivate.post(url, JSON.stringify(data), config);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  /**
   * Update grade task
   * @param {Object} data object fill with class_id, subject_id, title, and grade array
   * @returns Promise
   */
  async updateGradeTask(data) {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const url = `/v1/teacher/grade/class-task-grade`;
      const response = await axiosPrivate.put(url, JSON.stringify(data), config);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  /**
   * Index tugasku task
   * @param {string} id id of room
   * @returns Promise
   */
  async indexTugaskuTask(id) {
    try {
      const url = `/v1/teacher/grade/tugasku-task/${id}`;
      const response = await axiosPrivate.get(url);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  /**
   * Index grade tugasku task
   * @param {string} type type consist quiz or task with value id
   * @returns Promise
   */
  async indexGradeTugaskuTask(type) {
    try {
      const config = {
        params: { type },
      };
      const url = `v1/teacher/grade/tugasku-grade`;
      const response = await axiosPrivate.get(url, config);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  async getTahunAjaran(student_id) {
    try {
      const { accessRoleApiUrlPrefix: prefix } = getUserProperty();
      const config = { params: { student_id } };

      const url = `/v1/${prefix}/tahun_ajaran_by_siswa`;
      const res = await axiosPrivate.get(url, config);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  /**
   *
   * @param {1 | 2} semester
   * @param {number} tahun_ajaran_id
   * @param {number} student_id
   */
  async getGrades(semester, tahun_ajaran_id, student_id = null) {
    try {
      const { accessRoleApiUrlPrefix: prefix } = getUserProperty();
      const config = { params: { semester, tahun_ajaran_id, student_id } };

      let url = null;
      if (prefix === 'user') url = '/v1/user/grade';
      if (prefix === 'parent' || prefix === 'teacher') {
        url = `/v1/${prefix}/student/${student_id}/grade`;
      }

      const res = await axiosPrivate.get(url, config);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  /**
   *
   * @param {1 | 2} semester
   * @param {number} tahun_ajaran_id
   * @param {number} subject_id
   * @param {number} student_id
   */
  async getGradeDetail(semester, tahun_ajaran_id, subject_id, student_id = null) {
    try {
      const { accessRoleApiUrlPrefix: prefix } = getUserProperty();
      const config = { params: { semester, tahun_ajaran_id, subject_id, student_id } };

      let url = null;
      if (prefix === 'user') url = '/v1/user/grade/detail';
      if (prefix === 'parent' || prefix === 'teacher') {
        url = `/v1/${prefix}/student/${student_id}/grade/detail`;
      }

      const res = await axiosPrivate.get(url, config);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  /**
   *
   * @param {string} q
   * @param {number} per_page
   * @param {number} page
   * @returns promise
   */
  async getClassStudent(q = null, per_page = 25, page = 1) {
    try {
      const config = { params: { q, per_page, page } };
      const url = `/v1/teacher/school/class`;
      const res = await axiosPrivate.get(url, config);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  /**
   *
   * @param {string} q
   * @param {number} per_page
   * @param {number} page
   * @param {number} class_id require
   * @returns promise
   */
  async getStudentByClass(q = null, per_page = 25, page = 1, class_id = null) {
    try {
      const config = { params: { q, per_page, page, class_id } };
      const url = `/v1/teacher/school/class/${class_id}/student`;
      const res = await axiosPrivate.get(url, config);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },

  async getStudentByClassV2({ q = null, per_page = 25, page = 1, class_id = null }) {
    try {
      const config = {
        params: { q, per_page, page, class_id },
      };
      const url = `/v1/teacher/school/class/${class_id}/student`;
      const res = await axiosPrivate.get(url, config);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(getError(error));
    }
  },
};

export default gradeServices;
