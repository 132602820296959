import { axiosPrivate } from 'configs/axios-instance.configs';
import { getError } from 'utils/service/service.utils';
import { getUserProperty } from './contracts/user.contract';

const attitudeNoteService = {
  /**
   * @param {Number} user_id - student id(if parent)
   * @returns Promise
   */
  async getSchoolYearActive(user_id) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = 'v1/tahun_ajaran/active';
        const response = await axiosPrivate.get(url, { params: { user_id } });

        resolve(response);
      } catch (error) {
        reject(getError(error));
      }
    });
  },

  /**
   * @param {Number} school_id - for role parent
   * @returns Promise
   */
  async getSchoolSubjects(school_id) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = 'v1/teacher/subjects';
        const response = await axiosPrivate.get(url, { params: { school_id } });

        resolve(response);
      } catch (error) {
        reject(getError(error));
      }
    });
  },

  /**
   * @param {Object} param
   * @param {String} param.q
   * @param {Number} param.per_page
   * @param {Number} param.page
   * @returns Promise
   */
  async getSchoolClassStudent({ q, per_page, page }) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `/v1/teacher/school/class`;
        const response = await axiosPrivate.get(url, { params: { q, per_page, page } });
        resolve(response);
      } catch (error) {
        reject(getError(error));
      }
    });
  },

  /**
   * @param {Object} param
   * @param {String} param.q
   * @param {Number} param.per_page
   * @param {Number} param.page
   * @param {Number} param.class_id
   * @returns Promise
   */
  async getSchoolStudent({ q, per_page, page, class_id }) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `/v1/teacher/school/class/${class_id}/student`;
        const response = await axiosPrivate.get(url, { params: { q, per_page, page, class_id } });

        resolve(response);
      } catch (error) {
        reject(getError(error));
      }
    });
  },

  /**
   * @param {Object} dataObject
   * @returns Promise
   */
  async createNote(dataObject) {
    return new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData();
        for (let key in dataObject) {
          if (dataObject[key] !== '' || dataObject[key] !== null) {
            formData.append(key, dataObject[key]);
          }
        }

        const url = `/v1/teacher/attitude_notes`;
        const response = await axiosPrivate.post(url, formData);

        resolve(response);
      } catch (error) {
        reject(getError(error));
      }
    });
  },

  /**
   *
   * @param {Object} param
   * @param {Number} param.tahun_ajaran_id
   * @param {Number} param.semester
   * @param {Number} param.subject_id
   * @param {Number} param.noteId
   * @param {Number} param.user_id
   * @returns Promise
   */
  async getNote({
    tahun_ajaran_id = null,
    semester = 1,
    subject_id = null,
    noteId = null,
    user_id = null,
  }) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = '';
        const { accessRoleApiUrlPrefix: prefix } = getUserProperty();
        const config = {};

        if (!noteId) {
          config.params = { tahun_ajaran_id, semester, subject_id, user_id };
          url = `/v1/${prefix}/attitude_notes`;
        } else {
          url = `/v1/${prefix}/attitude_notes/${noteId}`;
        }

        const response = await axiosPrivate.get(url, config);
        resolve(response);
      } catch (error) {
        reject(getError(error));
      }
    });
  },

  /**
   *
   * @param {Number} tahun_ajaran_id
   * @param {Number} semester
   * @param {Number} user_id
   * @returns Promise
   */
  async getNoteSubjectList(tahun_ajaran_id, semester, user_id) {
    return new Promise(async (resolve, reject) => {
      try {
        const { accessRoleApiUrlPrefix: prefix } = getUserProperty();

        const url = `/v1/${prefix}/attitude_notes/subject`;
        const response = await axiosPrivate.get(url, {
          params: {
            tahun_ajaran_id,
            semester,
            user_id,
          },
        });
        resolve(response);
      } catch (error) {
        reject(getError(error));
      }
    });
  },
  /**
   *
   * @param {Object} dataObject
   * @param {Number} noteId
   * @returns Promise
   */
  async updateNote(dataObject, noteId) {
    return new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData();

        const allowedData = [
          'type',
          'instrument',
          'grade',
          'description',
          'follow_up_desc',
          'send_notif',
        ];

        for (let key in dataObject) {
          if (dataObject[key] && allowedData.includes(key)) {
            formData.append(key, dataObject[key]);
          }
        }

        const url = `/v1/teacher/attitude_notes/${noteId}`;
        const response = await axiosPrivate.post(url, formData);
        resolve(response);
      } catch (error) {
        reject(getError(error));
      }
    });
  },

  /**
   *
   * @param {Number} noteId
   * @returns Promise
   */
  async removeNote(noteId) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `/v1/teacher/attitude_notes/${noteId}`;
        const response = await axiosPrivate.delete(url);

        resolve(response);
      } catch (error) {
        reject(getError(error));
      }
    });
  },

  /**
   *
   * @param {Number} tahun_ajaran_id
   * @param {Number} semester
   * @returns Promise
   */
  async printNote(tahun_ajaran_id, semester) {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          params: { tahun_ajaran_id, semester },
          responseType: 'blob',
        };

        let url = `v1/teacher/attitude_notes/print`;
        const res = await axiosPrivate.get(url, config);
        resolve(res);
      } catch (error) {
        reject(getError(error));
      }
    });
  },
};

export default attitudeNoteService;
