const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
/**
 * helper to crete group routes
 * @param {object} p
 * @param {object[]} p.routes
 * @param {element} p.routeComponent
 * @param {element} p.closeEnvPage
 * @returns
 */
export const createGroupRoute = ({
  routes,
  routeComponent: RouteComponent,
  closeEnvPage: CloseEnvPage,
}) => {
  return routes.map(props => {
    if (props.closeEnv && props.closeEnv.includes(ENVIRONMENT)) {
      return <RouteComponent {...props} key={props.path} component={CloseEnvPage} />;
    }

    return <RouteComponent {...props} key={props.path} />;
  });
};
