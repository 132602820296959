import React from 'react';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';
import Logo from 'stories/components/general/logo/logo.component';
import PropTypes from 'prop-types';
import studentImg from 'assets/images/banner_siswa.svg';
import parentImg from 'assets/images/banner_orangtua.svg';
import teacherImg from 'assets/images/banner_guru.svg';
import verifyImg from 'assets/images/img_password.svg';
import backIcon from 'assets/icons/ic_nav_action_back.svg';
import flowTop from 'assets/images/flowy_top.svg';
import { useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 32px;

  > img {
    width: 100px;
  }

  @media ${device.laptop} {
    margin-bottom: 80px;

    > img {
      display: none;
    }
  }
`;

const IconLeftWrapper = styled.div`
  position: relative;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  left: 0;
  cursor: pointer;

  @media ${device.laptop} {
    left: 20px;
  }
`;

const ImgFlowTop = styled.img`
  display: block !important;
  width: 200px !important;
  position: absolute;
  right: 0;
  top: 0;
  @media ${device.laptop} {
    max-width: ${device.laptop};
    width: 280px !important;
  }
`;

/**
 *
 * @param {object} p
 * @param {'student'|'teacher'|'parent'|'verify'} p.type
 * @returns
 */

export default function HeaderBar({ type }) {
  const history = useHistory();
  const { path } = useRouteMatch();

  if (type) {
    return (
      <Header>
        <Logo />

        {type === 'student' && <img src={studentImg} alt={type} />}
        {type === 'parent' && <img src={parentImg} alt={type} />}
        {type === 'teacher' && <img src={teacherImg} alt={type} />}
        {type === 'verify' && <img src={verifyImg} alt={type} />}
      </Header>
    );
  }

  return (
    <Header>
      {path !== '/' && (
        <IconLeftWrapper onClick={() => history.goBack()}>
          <img src={backIcon} alt="" />
        </IconLeftWrapper>
      )}
      <Logo />
      {path === '/' && <ImgFlowTop src={flowTop} alt="image-flow" />}
    </Header>
  );
}

HeaderBar.propTypes = {
  type: PropTypes.oneOf(['student', 'teacher', 'parent', 'verify', null]),
};
