import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import SnackbarProvider from 'react-simple-snackbar';
import { createBrowserHistory } from 'history';

import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/800.css';

import 'rsuite/dist/styles/rsuite-default.css';
import './assets/styles/style.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import store, { persistor } from 'store/store';
import { LoadingPage } from 'components/Loading';

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  const RELEASE = process.env.REACT_APP_SENTRY_RELEASE;
  const DSN = process.env.REACT_APP_SENTRY_DSN;

  if (RELEASE && DSN) {
    Sentry.init({
      dsn: DSN,
      integrations: [new Integrations.BrowserTracing()],
      environment: process.env.REACT_APP_ENVIRONMENT,
      release: RELEASE,
    });
  }
}

export const customHistory = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<LoadingPage />} persistor={persistor}>
        <CookiesProvider>
          <Router history={customHistory}>
            <SnackbarProvider>
              <App />
            </SnackbarProvider>
          </Router>
        </CookiesProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
