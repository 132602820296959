import USER_ACTION_TYPES from './user.types';

const INITIAL_STATE = {
  currentUser: {},
  role: 'guest',
  isAuthLoading: true,
  isAuthenticated: false,
  error: null,
  isGeneralSignInLoading: false,
  isGoogleSignInLoading: false,
  signInError: {
    signInType: null, // general | google | facebook | null
    message: null,
  },
  isSignOutLoading: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_ACTION_TYPES.SIGN_IN_START:
      return {
        ...state,
        isGeneralSignInLoading: Boolean(payload === 'general'),
        isGoogleSignInLoading: Boolean(payload === 'google'),
        signInError: { signInType: payload, message: null },
      };

    case USER_ACTION_TYPES.SIGN_IN_SUCCESS:
      return {
        currentUser: payload.user,
        role: payload.userRole,
        isAuthLoading: false,
        isAuthenticated: true,
        error: null,
        signInError: { signInType: null, message: null },
        isGeneralSignInLoading: false,
        isGoogleSignInLoading: false,
      };

    case USER_ACTION_TYPES.SIGN_IN_FAILED:
      return {
        ...INITIAL_STATE,
        isAuthLoading: false,
        signInError: payload,
      };

    case USER_ACTION_TYPES.SIGN_OUT_START:
      return {
        ...state,
        isSignOutLoading: true,
        error: null,
      };

    case USER_ACTION_TYPES.SIGN_OUT_SUCCESS:
      return {
        ...INITIAL_STATE,
        isAuthLoading: false,
        error: null,
      };

    case USER_ACTION_TYPES.SIGN_OUT_FAILED:
      return {
        ...state,
        isSignOutLoading: false,
        error: payload,
      };

    case USER_ACTION_TYPES.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: payload.user,
        role: payload.userRole ? payload.userRole : state.role,
      };

    case USER_ACTION_TYPES.SET_CURRENT_USER_IMAGE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          image: payload,
        },
      };

    default:
      return state;
  }
};

export default userReducer;
