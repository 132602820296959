// src/redux/host/host.reducer.js

import { SET_HOST } from './host.action';

const initialState = {
  host: null, // Initial state is null until the host is set
};

const hostReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOST:
      return {
        ...state,
        host: action.payload,
      };
    default:
      return state;
  }
};

export default hostReducer;
